import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import './Contact.css';

const Contact = () => {
    return (
        <div>
            <SettingsTitle title={'Contact'} />
            <div className="contact-info">
                <div className="contact">
                    <p>Email: info@revorium.com</p>
                    <a className="contact-btn" href="mailto:info@revorium.com">
                        Email Now
                    </a>
                </div>
                <div className="contact">
                    <p>BD: +8801746418385</p>
                    <a className="contact-btn" href="tel:+8801746418385">
                        Call Now
                    </a>
                </div>
                <div className="contact">
                    <p>Int: +37257836500</p>
                    <a className="contact-btn" href="tel:+37257836500">
                        Call Now
                    </a>
                </div>
                <p></p>
            </div>
        </div>
    );
};

export default Contact;
