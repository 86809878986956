import getToken from 'api/getToken';
import { useState } from 'react';
import Loading from '../../Loading/Loading';
import DocumentUpload from '../DocumentUpload/DocumentUpload';
import SettingsButton from '../SettingsButton/SettingsButton';
import './DocumentsUploadArea.css';
function DocumentsUploadArea({
    updateDocumentData,
    addDocumentData,
    openingBox,
    nid,
    passport,
    driving,
    status = undefined,
    id = undefined
}) {
    const documentID = nid ? 1 : passport ? 2 : 3;
    const [selectedImageFront, setSelectedImageFront] = useState(null);
    const [selectedImageBack, setSelectedImageBack] = useState(null);
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const uploadDocument = async () => {
        setIsLoading(true);
        const formData = new FormData();
        if (documentID === 1) {
            formData.append('images', selectedImageFront);
            formData.append('images', selectedImageBack);
        } else formData.append('image', selectedImageFront);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/upload/upload_document/${documentID}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + (await getToken())
                    },
                    body: formData
                }
            );
            const responseData = await response.json();

            setMsg(responseData.message);
            if (response.ok) {
                if (id && status === 'Rejected') updateDocumentData(id, responseData.data);
                else addDocumentData(responseData.data);
                setMsg(responseData.msg);
            } else {
            }
        } catch (err) {
            setMsg('Failed: ' + err.message);
        }
        setIsLoading(false);
    };

    const isDisable = () => {
        if (nid) {
            if (selectedImageFront && selectedImageBack) {
                return '';
            } else {
                return 'disabled';
            }
        }
        if (passport || driving) {
            if (selectedImageFront) {
                return '';
            } else {
                return 'disabled';
            }
        }
    };

    const onImageChangeFront = (file) => {
        setSelectedImageFront(file);
    };

    const onImageChangeBack = (file) => {
        setSelectedImageBack(file);
    };

    return (
        <>
            {msg && (
                <div className="tooltip">
                    <span className="tooltiptext">{msg}</span>
                </div>
            )}
            {isLoading && <Loading />}
            {!isLoading && (
                <div className={!openingBox ? 'close-document-box' : 'open-document-box'}>
                    <form>
                        <div className="documents-upload-area">
                            {selectedImageFront && (
                                <div className="document-upload">
                                    <img
                                        alt="not found"
                                        width={'150px'}
                                        src={URL.createObjectURL(selectedImageFront)}
                                    />
                                    <br />
                                    <div>
                                        <SettingsButton
                                            className={'remove-btn'}
                                            onClick={() => setSelectedImageFront(null)}
                                        >
                                            Remove
                                        </SettingsButton>
                                    </div>
                                </div>
                            )}

                            {selectedImageBack && (
                                <div className="document-upload">
                                    <img
                                        alt="not found"
                                        width={'150px'}
                                        src={URL.createObjectURL(selectedImageBack)}
                                    />
                                    <br />
                                    <div>
                                        <SettingsButton
                                            className={'remove-btn'}
                                            onClick={() => setSelectedImageBack(null)}
                                        >
                                            Remove
                                        </SettingsButton>
                                    </div>
                                </div>
                            )}

                            {nid && (
                                <>
                                    {!selectedImageFront && (
                                        <DocumentUpload
                                            onFileChange={onImageChangeFront}
                                            title={'Front side of your document'}
                                            subtitle={'Upload the front side of your document'}
                                        />
                                    )}

                                    {!selectedImageBack && (
                                        <DocumentUpload
                                            onFileChange={onImageChangeBack}
                                            title={'Back side of your document'}
                                            subtitle={'Upload the back side of your document'}
                                        />
                                    )}
                                </>
                            )}

                            {passport && (
                                <>
                                    {!selectedImageFront && (
                                        <DocumentUpload
                                            onFileChange={onImageChangeFront}
                                            title={'Details page with the picture'}
                                            subtitle={'Upload the photo page of your passport.'}
                                        />
                                    )}
                                </>
                            )}

                            {driving && (
                                <>
                                    {!selectedImageFront && (
                                        <DocumentUpload
                                            onFileChange={onImageChangeFront}
                                            title={'Your driver’s licence'}
                                            subtitle={'Upload the of your document'}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        <div className="documents-upload-btn">
                            <SettingsButton onClick={uploadDocument} disabled={isDisable()}>
                                Submit
                            </SettingsButton>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}

export default DocumentsUploadArea;
