import { useEffect, useState } from 'react';
import apiCall from '../../../../api/apiCall';

import fiverrIcon1 from '../../../../images/fiverr-icon1.svg';
import fiverrIcon2 from '../../../../images/fiverr-icon2.svg';
import otherIcon from '../../../../images/Other.svg';
import upworkIcon from '../../../../images/upwork.svg';
import Loading from '../../../Loading/Loading';
import { getAccessToken } from '../../../Login/Auths';
import SettingsButton from '../../../Shared/SettingsButton/SettingsButton';
import SettingsPortfolio from '../../../Shared/SettingsPortfolio/SettingsPortfolio';
import SettingsPortfolioAdded from '../../../Shared/SettingsPortfolioAdded/SettingsPortfolioAdded';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import MultipleShimmer from './MultipleShimmer';
import './Portfolio.css';
const Portfolio = () => {
    const token = getAccessToken();
    const [email, setEmail] = useState();
    const [link, setLink] = useState([]);
    const [password, setPassword] = useState();
    const [portfolioData, setPortfolioData] = useState();
    const [url, setUrl] = useState();
    const [accountName, setAccountName] = useState();
    const [portfolioJSX, setPortfolioJSX] = useState([]);
    const [notHaveAccount, setNotHaveAccount] = useState([]); //'fiverr', 'upwork', 'other'

    const [isLoading, setIsLoading] = useState(false);

    const [msg, setMsg] = useState();

    useEffect(() => {
        document.title = 'Revorium - Portfolio';
    });

    const getFreelanceAccount = async () => {
        setIsLoading(true);

        const response = await apiCall(`/freelancer_account`);

        setIsLoading(false);
        if (response.ok) {
            setPortfolioData(response.data);
        } else {
            setMsg(response.msg);
        }
    };

    useEffect(() => {
        getFreelanceAccount();
    }, []);

    const addFreelanceAccount = async (accountName) => {
        setIsLoading(true);

        const response = await apiCall(`/freelancer_account`, 'POST', {
            account_name: accountName,
            url
        });

        setIsLoading(false);

        if (response.ok) {
            setPortfolioData([...portfolioData, response.data]);
            setMsg(response.msg);
        } else {
            setMsg(response.msg);
        }
    };

    useEffect(() => {
        if (!portfolioData) return;
        const tempArr = [];
        setNotHaveAccount([]);
        for (let i = 0; i < portfolioData.length; i++) {
            const arr = notHaveAccount;
            arr.push(portfolioData[i].account_name);
            setNotHaveAccount(arr);
            if (portfolioData[i].account_name === 'fiverr') {
                tempArr.push(
                    <div key={portfolioData[i].id} className="portfolio-added-area">
                        <SettingsPortfolioAdded
                            label={'Fiverr'}
                            icon={fiverrIcon1}
                            btnIcon={fiverrIcon2}
                            link={portfolioData[i].url}
                        />
                    </div>
                );
            } else if (portfolioData[i].account_name === 'upwork') {
                tempArr.push(
                    <div key={portfolioData[i].id} className="portfolio-added-area">
                        <SettingsPortfolioAdded
                            label={'Upwork'}
                            icon={upworkIcon}
                            btnIcon={upworkIcon}
                            link={portfolioData[i].url}
                        />
                    </div>
                );
            } else if (portfolioData[i].account_name === 'other') {
                tempArr.push(
                    <div key={portfolioData[i].id} className="portfolio-added-area">
                        <SettingsPortfolioAdded
                            label={'Other'}
                            icon={otherIcon}
                            btnIcon={otherIcon}
                            link={portfolioData[i].url}
                        />
                    </div>
                );
            }
        }

        setPortfolioJSX(tempArr);
    }, [portfolioData]);

    return (
        <div>
            <SettingsTitle title={'Portfolio'} />
            {isLoading && <Loading />}
            {msg && <p>{msg}</p>}
            <div className="portfolio-main">
                {isLoading && (
                    <>
                        <MultipleShimmer />
                        <MultipleShimmer />
                        <MultipleShimmer />
                    </>
                )}
                {!isLoading && (
                    <>
                        {portfolioJSX}
                        {!notHaveAccount.includes('fiverr') && (
                            <div className="portfolio-field-area">
                                <p>Fiverr</p>
                                <div className="portfolio-link-area">
                                    <SettingsPortfolio setUrl={setUrl} label={'Fiverr'} />
                                    <SettingsButton
                                        onClick={() => {
                                            setAccountName(() => 'fiverr');
                                            addFreelanceAccount('fiverr');
                                        }}
                                    >
                                        Add
                                    </SettingsButton>
                                </div>
                            </div>
                        )}
                        {!notHaveAccount.includes('upwork') && (
                            <div className="portfolio-field-area">
                                <p>Upwork</p>
                                <div className="portfolio-link-area">
                                    <SettingsPortfolio setUrl={setUrl} label={'Upwork'} />
                                    <SettingsButton
                                        onClick={() => {
                                            setAccountName(() => 'upwork');
                                            addFreelanceAccount('upwork');
                                        }}
                                    >
                                        Add
                                    </SettingsButton>
                                </div>
                            </div>
                        )}
                        {!notHaveAccount.includes('other') && (
                            <div className="portfolio-field-area">
                                <p>Other</p>
                                <div className="portfolio-link-area">
                                    <SettingsPortfolio setUrl={setUrl} label={'Other'} />
                                    <SettingsButton
                                        onClick={() => {
                                            setAccountName(() => 'other');
                                            addFreelanceAccount('other');
                                        }}
                                    >
                                        Add
                                    </SettingsButton>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Portfolio;
