import { useRef } from 'react';
import { GrClose } from 'react-icons/gr';
import WithdrawMessageImg from '../../../../images/withdraw-message.png';
import Modal from '../../../Shared/Modal/Modal';
import './WithdrawMessage.css';

const WithdrawMessage = ({ data, onClose, withdraw_time }) => {
    const childRef = useRef();
    return (
        <Modal ref={childRef} className={'modal-sidebar'} onClose={() => onClose()}>
            <div className="modal-sidebar-content">
                <div className="withdraw-top">
                    <div className="close-icon">
                        <GrClose onClick={() => (onClose ? childRef.current.closeModal() : '')} />
                    </div>
                </div>
                <div className="withdraw-message">
                    <div>
                        <img src={WithdrawMessageImg} alt="" />
                    </div>
                    <div>
                        <h3>-{data.amount}USD</h3>
                        <h4>
                            {data.bank.bank_name} ({String(data.bank.account_number).slice(-4)})
                        </h4>
                        <p>Your deposit in progress it may take up to {withdraw_time} mins.</p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WithdrawMessage;
