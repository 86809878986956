import { useRef, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Loading from '../../../Loading/Loading';
import Modal from '../../../Shared/Modal/Modal';
import './Info.css';

function Info({ onClose }) {
    const childRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Modal ref={childRef} className={'modal-sidebar'} onClose={() => onClose()}>
                {true && (
                    <div className="modal-sidebar-content">
                        {isLoading && <Loading />}
                        <div className="withdraw-top">
                            <div className="close-icon">
                                <GrClose onClick={() => childRef.current.closeModal()} />
                            </div>
                            <h3>Informations</h3>
                            <div className="total-info-container">
                                <div className="info-paragraph-container">
                                    <p className="info-pending">
                                        <span>Pending</span>
                                    </p>
                                    <div>
                                        The client hasn’t paid the invoice yet. The invoice is
                                        waiting to be paid by the client.
                                    </div>
                                </div>
                                <div className="info-paragraph-container">
                                    <p className="info-settlement">
                                        <span> Settlement</span>
                                    </p>
                                    <div>
                                        The client has already paid the invoice and the payment is
                                        under processing by the payment processing gateway, eg.
                                        Mastercard, or Visa for settlement by checking different
                                        aspects of the transaction like fraud, money laundering,
                                        etc.
                                    </div>
                                </div>
                                <div className="info-paragraph-container">
                                    <p className="info-total">
                                        <span> Total Earnings</span>
                                    </p>
                                    <div>
                                        Payment has been successfully settled by the processing
                                        gateway and credited to the total earnings and is ready to
                                        withdraw or send and spend.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
}

export default Info;
