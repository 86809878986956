import SubmitButton from 'components/LoginRegister/FormFields/SubmitButton/SubmitButton';
import TextArea from 'components/LoginRegister/FormFields/TextArea/TextArea';
import TextInput from 'components/LoginRegister/FormFields/TextInput/TextInput';
import React, { useEffect, useState } from 'react';
import { DatePicker } from 'react-rainbow-components';

const PersonalDetails = ({ setPageState, setUserData, userData, setIsLoading }) => {
    const [isDobValid, setIsDobValid] = useState(false);
    const [msg, setMsg] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPageState('submit');
    };

    useEffect(() => {
        setMsg('');
        setIsDobValid(false);

        if (userData.dob) {
            const minAge = new Date();
            minAge.setFullYear(minAge.getFullYear() - 18);
            const maxAge = new Date('1901-01-01');

            if (new Date(userData.dob) > minAge) {
                setMsg('You Must 18 Years old to register to Revorium');
                return;
            }

            if (new Date(userData.dob) < maxAge) {
                setMsg('Birth Year cannot be less than 1900');
                return;
            }

            setIsDobValid(true);
        }
    }, [userData.dob]);

    return (
        <form onSubmit={handleSubmit}>
            {msg && <p>{msg}</p>}

            <div className="personal-details-area">
                <div className="personal-details">
                    <TextInput
                        type="text"
                        value={userData.name}
                        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                        placeholder="Name"
                        required
                    />
                    <div className={`form-date-input-field`}>
                        <DatePicker
                            className="signup-datepicker"
                            value={userData.dob}
                            onChange={(d) => setUserData({ ...userData, dob: d })}
                            formatStyle="large"
                            placeholder="Date of Birth"
                        />
                    </div>
                    <TextInput
                        value={userData.city}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                        placeholder="City"
                        required
                    />
                    <TextInput
                        value={userData.postal_code}
                        onChange={(e) => setUserData({ ...userData, postal_code: e.target.value })}
                        type="text"
                        placeholder="Postal Code"
                        required
                    />
                </div>
                <TextArea
                    value={userData.address}
                    placeholder="Address"
                    onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                    cols="10"
                    rows="3"
                />
                <SubmitButton
                    type="submit"
                    disabled={
                        userData.name &&
                        userData.dob &&
                        userData.city &&
                        userData.address &&
                        isDobValid
                            ? ''
                            : 'disabled'
                    }
                >
                    Sign Up
                </SubmitButton>
            </div>
        </form>
    );
};

export default PersonalDetails;
