import { useEffect, useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import { MdDoneAll } from 'react-icons/md';
import { Shimmer } from 'react-shimmer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiCall from '../../../../api/apiCall';
import walletIcon from '../../../../images/wallet.svg';
import { getAccessToken } from '../../../Login/Auths';
import './NoteCard.css';

const NoteCard = (props) => {
    const [paylink_key, setPaylinkKey] = useState();
    const [editAble, setEditAble] = useState(true);
    const [isLoading, setIsLoading] = useState();
    const [isShow, setIsShow] = useState(false);
    const [msg, setMsg] = useState();
    const token = getAccessToken();
    const [previousKey, setPreviousKey] = useState();

    useEffect(() => {
        if (props) {
            if (props.paylink_key) {
                setPreviousKey(props.paylink_key);
                setPaylinkKey(props.paylink_key);
            }
            setIsLoading(props.isLoading);
        }
    }, [props]);

    const updatePaylinkKey = async (e) => {
        setEditAble(true);
        if (previousKey === paylink_key) return;
        e && e.preventDefault();
        setEditAble(true);
        setIsLoading(true);

        const responseData = await apiCall(`/user`, 'PATCH', { paylink_key });

        toast(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
        });

        if (responseData.ok) {
            setMsg(responseData.message);
        } else {
            setMsg(responseData.message);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        isShow &&
            setTimeout(() => {
                setIsShow(false);
            }, 3000);
    }, [isShow]);

    const saveKey = async () => {
        !editAble && updatePaylinkKey();
    };

    return (
        <div className="note-card" onClick={saveKey}>
            {isLoading ? (
                <>
                    <Shimmer width={'100%'} height={'50px'} />
                    <br></br>
                    <Shimmer width={'100%'} height={'105px'} />
                </>
            ) : (
                <>
                    <div className="note-card-title-area">
                        <div className="note-card-title">
                            <div>
                                <img src={walletIcon} alt="" />
                            </div>
                            <div className="note-title">
                                <div>revorium.com/user/</div>
                                <div>
                                    {!editAble ? (
                                        <input
                                            type="text"
                                            value={paylink_key}
                                            onChange={(e) => setPaylinkKey(e.target.value)}
                                            autoFocus
                                            onBlur={updatePaylinkKey}
                                        />
                                    ) : (
                                        <span
                                            onDoubleClick={() => setEditAble(!editAble)}
                                            style={{
                                                display: 'inline',
                                                height: '25px',
                                                minWidth: '300px'
                                            }}
                                        >
                                            {paylink_key}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="note-card-icon">
                            <span className="note-card-edit">
                                {editAble ? (
                                    <FiEdit2 onClick={() => setEditAble(false)} />
                                ) : (
                                    <MdDoneAll onClick={updatePaylinkKey} />
                                )}
                            </span>
                            <span className="note-card-copy">
                                <AiOutlineCopy
                                    onClick={() => {
                                        setIsShow(true);
                                        navigator.clipboard.writeText(
                                            'revorium.com/user/' + paylink_key
                                        );
                                    }}
                                />
                                {isShow && (
                                    <div className="tooltip-card">
                                        <span className="tooltiptext-card">Copied</span>
                                    </div>
                                )}
                                <ToastContainer />
                            </span>
                        </div>
                    </div>
                    <div className="note-card-text">
                        <p>
                            Revorium lets you recieve payments on the fly. You can generate invoice
                            or share the payment link to request the payment. Learn More
                        </p>
                        Learn More
                    </div>
                </>
            )}
        </div>
    );
};

export default NoteCard;
