import uploadIcon from '../../../images/upload.svg';
import './DocumentUpload.css';

function DocumentUpload({ title, subtitle, onFileChange }) {
    return (
        <div className="document-upload">
            <div className="upload-icon">
                <img src={uploadIcon} alt="" />
            </div>
            <h4>{title}</h4>
            <p>{subtitle}</p>
            <div className="choose-file">
                <input type="file" onChange={(e) => onFileChange(e.target.files[0])} />
            </div>
        </div>
    );
}

export default DocumentUpload;
