import './SettingsPortfolio.css';

const SettingsPortfolio = ({ setUrl }) => {
    return (
        <div className="portfolio-field">
            <input onChange={(e) => setUrl(e.target.value)} type="text" placeholder="Enter URL" />
        </div>
    );
};

export default SettingsPortfolio;
