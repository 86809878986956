import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import NoDataFound from '../../../Shared/NoDataFound/NoDataFound';
import RequestMoney from '../RequestMoney/RequestMoney';
import './Invoice.css';

const Invoice = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [searchKey, setSearchKey] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [searchedData, setSearchedData] = useState();
    const [tooltipId, setTooltipId] = useState(null);
    const [showSearchInput, setShowSearchInput] = useState(false);

    useEffect(() => {
        setSearchedData(props.data);
    }, [props.data]);

    const addInvoiceData = (data) => {
        setSearchedData([data, ...searchedData]);
    };

    useEffect(() => {
        if (searchedData && searchedData.length > 0) {
            setInvoiceData(
                searchedData.map((data, i) => (
                    <tr
                        // className={i % 2 === 0 ? 'odd-column single-invoice' : ' single-invoice'}
                        className={'single-invoice'}
                        key={data.id}
                    >
                        <td
                            onClick={() => {
                                setTooltipId(data.id);
                                navigator.clipboard.writeText(
                                    process.env.REACT_APP_REVO_PAYMENT_URL + data.invoice_id
                                );
                            }}
                        >
                            <div className="tooltip">
                                <span>
                                    <AiOutlineCopy />
                                </span>
                                <span>{data.invoice_id}</span>
                                {tooltipId === data.id ? (
                                    <span className="tooltiptext">Copied</span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </td>
                        {/* <td>{new Date(data.createdAt).toLocaleString('en-US', {})}</td> */}
                        <td>{moment(new Date(data.createdAt), 'MMDDYYYY').fromNow()}</td>
                        <td>{data.client_email}</td>
                        <td>${data.amount}</td>
                        <td>
                            <span
                                className={
                                    data.isPaid
                                        ? 'paid'
                                        : new Date() > new Date(data.due_date)
                                        ? 'expired'
                                        : 'pending'
                                }
                            >
                                {data.isPaid
                                    ? 'Paid'
                                    : new Date() > new Date(data.due_date)
                                    ? 'Expired'
                                    : 'Pending'}
                            </span>
                        </td>
                    </tr>
                ))
            );
        }
    }, [searchedData, tooltipId]);
    useEffect(() => {
        if (props.data && searchKey) {
            setSearchedData(
                props.data.filter(
                    (data) =>
                        data.invoice_id.toLowerCase().includes(searchKey.toLowerCase()) ||
                        data.client_email.toLowerCase().includes(searchKey.toLowerCase()) ||
                        data.amount.toString().toLowerCase().includes(searchKey.toLowerCase())
                )
            );
        } else setSearchedData(props.data);
    }, [searchKey]);

    return (
        <>
            {showModal && (
                <RequestMoney addInvoiceData={addInvoiceData} onClose={() => setShowModal(false)} />
            )}

            <div className="invoice-area">
                <div className="invoice-top">
                    <div className="invoice-title-btn-area">
                        <div className="invoice-title">
                            <h3>Invoice</h3>
                            <p>List of all your recent invoices</p>
                        </div>
                        <div>
                            <button onClick={() => setShowModal(true)} className="btn-invoice">
                                Request Money
                            </button>
                        </div>
                    </div>
                    <div className="search-area">
                        <div
                            className={!showSearchInput ? 'search' : 'search-animate'}
                            onClick={() => {
                                setShowSearchInput(true);
                            }}
                        >
                            {!showSearchInput && <p>Search</p>}
                            <span>
                                <BiSearch />
                            </span>
                            {showSearchInput && (
                                <input
                                    autoFocus
                                    onBlur={() => setShowSearchInput(false)}
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => setSearchKey(e.target.value)}
                                />
                            )}
                        </div>
                        <div>
                            <Link to="/invoice" className="see-all" state={props.data}>
                                See All
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="invoice-table">
                    {props.isLoading && (
                        <>
                            <Shimmer width={'100%'} height={'45px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'45px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'45px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'45px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'45px'} />
                        </>
                    )}
                    <table>
                        <thead>
                            <tr>
                                <th>Invoice ID</th>
                                <th>Date</th>
                                <th>Email</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>{!props.isLoading && invoiceData && invoiceData}</tbody>
                    </table>
                    {!props.isLoading && !invoiceData && <NoDataFound data="Invoices" />}
                </div>
            </div>
        </>
    );
};

export default Invoice;
