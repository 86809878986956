import { useEffect, useRef, useState } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import apiCall from '../../../../api/apiCall';
import bdIcon from '../../../../images/bd-icon.svg';
import circleBoldIcon from '../../../../images/circle-bold-icon.svg';
import circleCloseIcon from '../../../../images/circle-close-icon.svg';
import circleIcon from '../../../../images/circle-icon.svg';
import downArrow from '../../../../images/down_arrow.svg';
import usdIcon from '../../../../images/usd.svg';
import Loading from '../../../Loading/Loading';
import AddBankAccount from '../../../Shared/AddBankAccount/AddBankAccount';
import BankAccountInfo from '../../../Shared/BankAccountInfo/BankAccountInfo';
import Modal from '../../../Shared/Modal/Modal';
import WithdrawMessage from '../WithdrawMessage/WithdrawMessage';
import './Withdraw.css';

function Withdraw({ balance, onClose, updateBalance }) {
    const childRef = useRef();
    const [withdrawBalance, setWithdrawBalance] = useState(10);
    const [finalWithdrawBalance, setFinalWithdrawBalance] = useState();
    const [receiveBalance, setReceiveBalance] = useState(0);
    const [exchangeRate, setExchangeRate] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [bankData, setBankData] = useState([]);
    const [bankDataFields, setBankDataFields] = useState();
    const [selectedBankID, setSelectedBankID] = useState();
    const [isWithdrawEnabled, setIsWithdrawEnabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resData, setResData] = useState();
    const [balanceExceedErrorMsg, setBalanceExceedErrorMsg] = useState('');
    const [msg, setMsg] = useState();

    const [withdraw_time, setWithdrawTime] = useState();

    const [feeId, setFeeId] = useState();
    const [limits, setLimits] = useState();
    const [fees, setFees] = useState();
    const [selectedFee, setSelectedFee] = useState();
    const [isFeesOpen, setIsFeesOpen] = useState(false);
    const [feeText, setFeeText] = useState('Select Fee');

    useEffect(() => {
        document.title = 'Revorium -  Withdarw Money';
        getExchangeRate();
        getUserBanks();
        getPolicies();
    }, []);

    const getExchangeRate = async () => {
        setIsLoading(true);

        const response = await apiCall(`/exchange_rate`);

        if (response.ok) {
            const rate = response.data.filter((data) => data.exchange_code === 'USD/BDT');
            if (rate[0]) setExchangeRate(rate[0].exchange_rate);
            else setExchangeRate(0);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    const getUserBanks = async () => {
        setIsLoading(true);

        const response = await apiCall(`/bank`);

        if (response.ok) {
            setBankData(response.data);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        // ok
        if (!feeId) return;
        const fee = fees.find((f) => f.id == feeId);
        setSelectedFee(fee.value);
        setFeeText(fee.name);
        setWithdrawTime(fee.withdraw_time);
    }, [feeId]);

    const requestWithdrawl = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/withdraw`, 'POST', {
            bank_id: selectedBankID,
            withdraw_amount: withdrawBalance,
            fee_id: feeId
        });
        setIsLoading(false);
        // const responseData = await response.json();
        if (response.ok) {
            setMsg(response.msg);
            setIsLoading(false);
            const bank = bankData.filter((value, i) => value.id === selectedBankID);
            setResData({
                bank: bank[0],
                amount: withdrawBalance
            });
            updateBalance(withdrawBalance);
            setShowModal(true);
        } else {
            setMsg(response.msg);
        }
    };
    useEffect(() => {
        withdrawBalance && selectedFee && setFinalWithdrawBalance(withdrawBalance - selectedFee);
    }, [withdrawBalance, selectedFee]);
    useEffect(() => {
        if (!limits) return;
        const usdRules = limits.find((d) => d.currency === 'USD');
        usdRules && setWithdrawBalance(usdRules.minimum_amount);
    }, [limits]);
    useEffect(() => {
        finalWithdrawBalance &&
            selectedFee &&
            setWithdrawBalance(finalWithdrawBalance + selectedFee);
    }, [finalWithdrawBalance, selectedFee]);
    useEffect(() => {
        exchangeRate && setReceiveBalance(finalWithdrawBalance * exchangeRate);
    }, [exchangeRate, finalWithdrawBalance, selectedFee]);

    useEffect(() => {
        exchangeRate &&
            receiveBalance &&
            selectedFee &&
            setFinalWithdrawBalance(() => {
                return parseFloat(receiveBalance / exchangeRate);
            });
    }, [receiveBalance, exchangeRate, selectedFee]);

    const getPolicies = async () => {
        setIsLoading(true);

        const response = await apiCall(`/withdraw/policies`);

        if (response.ok) {
            // setCurrencyData(response.data);
            setLimits(response.data.limits);
            // const max =
            setFees(response.data.fees);
            setMsg('Success');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (bankData) {
            setBankDataFields(
                bankData.map((data, key) => (
                    <BankAccountInfo
                        data={data}
                        key={data.id}
                        className={'modal-sidebar-bank-info'}
                        onClick={() => bankData.length > 1 && setSelectedBankID(data.id)}
                        bankName={data.bank_name}
                        accountNumber={data.account_number}
                        bankId={selectedBankID}
                    />
                ))
            );
            if (bankData.length === 1) setSelectedBankID(bankData[0].id);
        }
    }, [bankData, selectedBankID]);

    useEffect(() => {
        if (isNaN(withdrawBalance)) setWithdrawBalance(0);
        if (withdrawBalance < 1) setWithdrawBalance(0);

        setMsg();
        setBalanceExceedErrorMsg();
        setIsWithdrawEnabled(false);
        if (!limits) return;
        const bdtRules = limits.find((d) => d.currency === 'BDT');
        const usdRules = limits.find((d) => d.currency === 'USD');
        if (balance < withdrawBalance) {
            setBalanceExceedErrorMsg('Insufficient funds to withdraw');
        } else if (withdrawBalance > usdRules.maximum_amount) {
            setBalanceExceedErrorMsg(
                'Withdraw amount must not exceed ' + usdRules.maximum_amount + 'USD'
            );
        } else if (withdrawBalance < usdRules.minimum_amount) {
            setBalanceExceedErrorMsg(
                'Withdraw amount must be at least ' + usdRules.minimum_amount + 'USD'
            );
        } else if (receiveBalance > bdtRules.maximum_amount) {
            setBalanceExceedErrorMsg(
                'Receive amount must not exceed ' + bdtRules.maximum_amount + 'BDT'
            );
        } else if (receiveBalance < bdtRules.minimum_amount) {
            setBalanceExceedErrorMsg(
                'Receive amount must be at least ' + bdtRules.minimum_amount + 'BDT'
            );
        } else {
            setBalanceExceedErrorMsg();
            if (
                !isNaN(withdrawBalance) &&
                !isNaN(exchangeRate) &&
                !isNaN(selectedBankID) &&
                withdrawBalance > 0 &&
                exchangeRate > 0 &&
                selectedBankID > 0
            )
                setIsWithdrawEnabled(true);
        }
    }, [
        withdrawBalance,
        receiveBalance,
        balance,
        limits,
        exchangeRate,
        selectedBankID,
        selectedFee
    ]);

    const feeButtonClicked = (e) => {
        e.preventDefault();
        setIsFeesOpen(!isFeesOpen);
    };

    useEffect(() => {
        if (fees && fees.length > 0) {
            setFeeId(fees[0].id);
        }
    }, [fees]);

    return (
        <>
            {showModal && resData && (
                <WithdrawMessage
                    data={resData}
                    withdraw_time={withdraw_time}
                    onClose={() => {
                        onClose();
                        setShowModal(false);
                    }}
                />
            )}
            {!showModal && (
                <Modal ref={childRef} className={'modal-sidebar'} onClose={() => onClose()}>
                    {true && (
                        <div className="modal-sidebar-content">
                            {isLoading && <Loading />}
                            <div className="withdraw-top">
                                <div className="close-icon">
                                    <GrClose onClick={() => childRef.current.closeModal()} />
                                </div>
                                <h3>Withdraw</h3>
                                <div className="withdraw-balance">
                                    <p>Available balance</p>
                                    <h3>{balance} USD</h3>
                                </div>
                            </div>
                            <div className="withdraw-balance-exceed-error">
                                {balanceExceedErrorMsg && <p>{balanceExceedErrorMsg}</p>}
                                {msg && <p>{msg}</p>}
                            </div>

                            <form action="">
                                <div className="exchange-input-fields">
                                    <div className="exchange-input-field-area">
                                        <div className="exchange-field">
                                            <span
                                                className={balanceExceedErrorMsg ? 'red-text' : ''}
                                            >
                                                &#36;
                                            </span>
                                            <input
                                                className={balanceExceedErrorMsg ? 'red-text' : ''}
                                                type="number"
                                                min="0"
                                                placeholder="0"
                                                value={+parseFloat(withdrawBalance).toFixed(2)}
                                                onChange={(e) =>
                                                    setWithdrawBalance(parseFloat(e.target.value))
                                                }
                                            />
                                        </div>
                                        <div className="field-icon">
                                            <label>
                                                <img width="20px" src={usdIcon} alt="" />
                                            </label>
                                            <label>USD</label>
                                        </div>
                                    </div>
                                    <div className="exchange-rate">
                                        <div className="exchange-top">
                                            <span>
                                                <img src={circleIcon} alt="" />
                                            </span>
                                            <span>{selectedFee} USD </span>
                                            <button
                                                className="fee-select"
                                                onClick={feeButtonClicked}
                                            >
                                                <span>{feeText}</span>
                                                <img src={downArrow}></img>
                                            </button>
                                            {isFeesOpen && (
                                                <div className="fees-div">
                                                    <p className="fees-div-title">Withdraw Types</p>
                                                    {/* {feesJSX} */}
                                                    {fees?.map((data) => (
                                                        <div
                                                            onClick={() => {
                                                                setFeeId(data.id);
                                                                setIsFeesOpen(false);
                                                            }}
                                                            className={
                                                                data.id === feeId
                                                                    ? 'single-fee-div selected-fee-div'
                                                                    : 'single-fee-div'
                                                            }
                                                            key={data.id}
                                                            value={data.id}
                                                        >
                                                            {/* {data.name} -> {data.description} -> {data.value} */}
                                                            <div>
                                                                <p className="first-line">
                                                                    <span>
                                                                        {data.name} - {data.value}{' '}
                                                                        {data.fee_currency}
                                                                    </span>
                                                                    <span>
                                                                        {data.withdraw_time} Min
                                                                    </span>
                                                                </p>
                                                                <p className="single-fee-description">
                                                                    {data.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        // <option key={data.id} value={data.id}>
                                                        //     {/* {data.name} -> {data.description} -> {data.value} */}
                                                        //     <div>
                                                        //         <p>
                                                        //             {data.name} - {data.value} {data.fee_currency}
                                                        //         </p>{' '}
                                                        //         <br></br>
                                                        //         <p>{data.description}</p>
                                                        //     </div>
                                                        // </option>
                                                    ))}
                                                </div>
                                            )}
                                            {/* <select
                                                className="fee-select"
                                                onChange={(e) => setFeeId(e.target.value)}
                                                value={feeId}
                                            >
                                                {feesJSX}
                                            </select> */}
                                            Fee
                                        </div>

                                        <div className="exchange-middle">
                                            <span>
                                                <img src={circleBoldIcon} alt="" />
                                            </span>
                                            <span>
                                                {finalWithdrawBalance?.toFixed(2)} USD Total amount
                                                we’ll convert
                                            </span>
                                        </div>
                                        <div className="exchange-bottom">
                                            <span>
                                                <img src={circleCloseIcon} alt="" />
                                            </span>
                                            <span>{exchangeRate} Exchange rate (24 hours)</span>
                                        </div>
                                    </div>
                                    <div className="exchange-input-field-area">
                                        <div className="exchange-field">
                                            <span>&#2547;</span>
                                            <input
                                                type="number"
                                                inputMode="decimal"
                                                min="0"
                                                placeholder="0"
                                                value={+parseFloat(receiveBalance).toFixed(2)}
                                                onChange={(e) =>
                                                    setReceiveBalance(parseFloat(e.target.value))
                                                }
                                            />
                                        </div>
                                        <div className="field-icon">
                                            <label>
                                                <img src={bdIcon} alt="" />
                                            </label>
                                            <label>BDT</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="select-bank-account-area">
                                    <h4 className="select-bank-account-title">
                                        Select a bank account to withdraw
                                    </h4>
                                    <div className="modal-sidebar-bank-account-list">
                                        {bankDataFields}
                                        {bankData.length === 1 && (
                                            <AddBankAccount
                                                className={'modal-sidebar-add-bank-account'}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="withdraw-button-area">
                                    <button
                                        onClick={requestWithdrawl}
                                        disabled={
                                            balanceExceedErrorMsg || !isWithdrawEnabled
                                                ? 'disabled'
                                                : ''
                                        }
                                    >
                                        <span>
                                            <AiOutlineArrowDown />
                                        </span>
                                        <span>Withdraw</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
}

export default Withdraw;
