import moment from 'moment';
import { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Modal from '../../../Shared/Modal/Modal';
import './WithdrawTransactionModal.css';
const WithdrawTransactionModal = ({ dataForModal, onClose }) => {
    const [transactionStatus, setTransactionStatus] = useState(dataForModal.status);
    return (
        <Modal className={'modal-sidebar'} onClose={() => onClose()}>
            <div className="modal-sidebar-content">
                <div className="withdraw-top">
                    <div className="close-icon">
                        <GrClose onClick={() => (onClose ? onClose() : '')} />
                    </div>
                    <div className="withraw-top-amount">
                        <div>
                            <h2>${dataForModal.withdraw.withdraw_amount.toFixed(2)}</h2>
                            <h4>
                                {dataForModal.withdraw.bank_name +
                                    '....' +
                                    String(dataForModal.withdraw.account_number).slice(-4)}
                            </h4>
                        </div>
                        <div>
                            {moment(new Date(dataForModal.createdAt)).format(
                                'Do MMM YYYY, h:mm:ss a'
                            )}
                        </div>
                    </div>
                    <div
                        className={`withdraw-status status-${transactionStatus.toLocaleLowerCase()}`}
                    >
                        {transactionStatus}
                    </div>
                </div>
                <div className="withdraw-transaction-details-area">
                    <h2>Transaction Details</h2>
                    <div className="withdraw-transaction-details">
                        <h3>Transaction ID</h3>
                        <h3>{dataForModal.transaction_id}</h3>
                    </div>
                    <div className="withdraw-transaction-details">
                        <h3>Withdraw Amount (USD)</h3>
                        <h3>${dataForModal.withdraw.withdraw_amount.toFixed(2)}</h3>
                    </div>
                    <div className="withdraw-transaction-details">
                        <h3>Deposit (BDT)</h3>
                        <h3>{dataForModal.withdraw.received_amount.toFixed(2)}</h3>
                    </div>
                    <div className="withdraw-transaction-details">
                        <h3>Exchange Rate</h3>
                        <h3>1.00 USD - {dataForModal.withdraw.exchange_rate} BDT</h3>
                    </div>
                    <div className="withdraw-transaction-details">
                        <h3>Withdraw Fee</h3>
                        <h3>{dataForModal.withdraw.withdraw_fee} BDT</h3>
                    </div>
                    <div className="withdraw-transaction-desc">
                        <h3>Descriptions</h3>
                        <p>{dataForModal.withdraw.note}</p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WithdrawTransactionModal;
