import { useEffect, useReducer, useState } from 'react';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auths';
import DocumentSelect from '../../../Shared/DocumentSelect/DocumentSelect';
import DocumentsUploadArea from '../../../Shared/DocumentsUploadArea/DocumentsUploadArea';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import './Documents.css';
const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case 'isNid':
            return {
                isNid: !state.isNid,
                isPassport: false,
                isDriving: false
            };
        case 'isPassport':
            return {
                isNid: false,
                isPassport: !state.isPassport,
                isDriving: false
            };
        case 'isDriving':
            return {
                isNid: false,
                isPassport: false,
                isDriving: !state.isDriving
            };
    }
};

function Documents() {
    const [document, dispatch] = useReducer(reducer, {});
    const [isLoading, setIsLoading] = useState(false);
    const [documentData, setDocumentData] = useState(null);
    const [msg, setMsg] = useState(null);
    const token = getAccessToken();
    const [uploadedDocs, setUploadedDocs] = useState([]);
    const [docJSX, setDocJSX] = useState([]);

    useEffect(() => {
        document.title = 'Revorium - Documents';
    }, []);
    const addDocumentData = (data) => {
        setDocumentData([...documentData, data]);
    };
    const updateDocumentData = (id, data) => {
        const arr = documentData.filter((prevItem) => prevItem.id !== id);
        arr.push(data);
        setDocumentData(arr);
    };
    const getDocuemnts = async () => {
        setIsLoading(true);

        const response = await apiCall(`/document`);

        if (response.ok) {
            setDocumentData(response.data);
            setMsg(response.msg);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getDocuemnts();
    }, []);

    useEffect(() => {
        if (!documentData) return;
        if (documentData.length === 0) return;

        const tempArr = [];
        setUploadedDocs([]);
        for (let i = 0; i < documentData.length; i++) {
            const arr = uploadedDocs;
            arr.push(documentData[i].doc_type_id);
            setUploadedDocs(arr);
            if (documentData[i].doc_type_id === 1) {
                tempArr.push(
                    <div key={documentData[i].id} className="documents-section">
                        <DocumentSelect
                            title={'Smart NID Document'}
                            subtitle={'Upload a photo or scan of your NID document'}
                            status={documentData[i].status}
                            onClick={() => dispatch({ type: 'isNid' })}
                        />

                        {documentData[i].status === 'Rejected' && (
                            <DocumentsUploadArea
                                updateDocumentData={updateDocumentData}
                                openingBox={document.isNid}
                                nid={document.isNid}
                                status={document.isNid && documentData[i].status}
                                id={document.isNid && documentData[i].id}
                            />
                        )}
                    </div>
                );
            } else if (documentData[i].doc_type_id === 2) {
                tempArr.push(
                    <div key={documentData[i].id} className="documents-section">
                        <DocumentSelect
                            title={'Passport Document'}
                            subtitle={'Upload a photo or scan of your passport photo page.'}
                            status={documentData[i].status}
                            onClick={() => dispatch({ type: 'isPassport' })}
                        />

                        {documentData[i].status === 'Rejected' && (
                            <DocumentsUploadArea
                                updateDocumentData={updateDocumentData}
                                openingBox={document.isPassport}
                                passport={document.isPassport}
                                status={document.isPassport && documentData[i].status}
                                id={document.isPassport && documentData[i].id}
                            />
                        )}
                    </div>
                );
            } else if (documentData[i].doc_type_id === 3) {
                tempArr.push(
                    <div key={documentData[i].id} className="documents-section">
                        <DocumentSelect
                            title={'Driving Licence'}
                            subtitle={'Upload a photo or scan of your passport photo page.'}
                            status={documentData[i].status}
                            onClick={() => dispatch({ type: 'isDriving' })}
                        />

                        {documentData[i].status === 'Rejected' && (
                            <DocumentsUploadArea
                                updateDocumentData={updateDocumentData}
                                openingBox={document.isDriving}
                                driving={document.isDriving}
                                status={document.isDriving && documentData[i].status}
                                id={document.isDriving && documentData[i].id}
                            />
                        )}
                    </div>
                );
            }
        }

        setDocJSX(tempArr);
    }, [documentData, document]);

    return (
        <div>
            <SettingsTitle
                title={'Documents'}
                subtitle={'Please provide us one of this documents to get verified'}
            />
            {isLoading && (
                <>
                    <div className="documents-section">
                        <Shimmer height={'86px'} width={'100%'} />
                    </div>
                    <div className="documents-section">
                        <Shimmer height={'86px'} width={'100%'} />
                    </div>
                    <div className="documents-section">
                        <Shimmer height={'86px'} width={'100%'} />
                    </div>
                </>
            )}
            {!isLoading && (
                <>
                    {docJSX}
                    <>
                        {!uploadedDocs.includes(1) && (
                            <div className="documents-section">
                                <DocumentSelect
                                    title={'Smart NID Document'}
                                    subtitle={'Upload a photo or scan of your NID document'}
                                    onClick={() => dispatch({ type: 'isNid' })}
                                />

                                <DocumentsUploadArea
                                    addDocumentData={addDocumentData}
                                    openingBox={document.isNid}
                                    nid={document.isNid}
                                    document={document}
                                />
                            </div>
                        )}
                        {!uploadedDocs.includes(2) && (
                            <div className="documents-section">
                                <DocumentSelect
                                    title={'Passport Document'}
                                    subtitle={'Upload a photo or scan of your passport photo page.'}
                                    onClick={() => dispatch({ type: 'isPassport' })}
                                />

                                <DocumentsUploadArea
                                    addDocumentData={addDocumentData}
                                    openingBox={document.isPassport}
                                    passport={document.isPassport}
                                />
                            </div>
                        )}
                        {!uploadedDocs.includes(3) && (
                            <div className="documents-section">
                                <DocumentSelect
                                    title={'Driving Licence'}
                                    subtitle={'Upload a photo or scan of your passport photo page.'}
                                    onClick={() => dispatch({ type: 'isDriving' })}
                                />

                                <DocumentsUploadArea
                                    addDocumentData={addDocumentData}
                                    openingBox={document.isDriving}
                                    driving={document.isDriving}
                                />
                            </div>
                        )}
                    </>
                </>
            )}
        </div>
    );
}

export default Documents;
