import React from 'react';
import { Shimmer } from 'react-shimmer';
import './MutlipleShimmer.css';
const MultipleShimmer = () => {
    return (
        <>
            <Shimmer className={'shimmer right-shimmer'} width={'25%'} height={'47px'} />
            <Shimmer className={'shimmer'} width={'65%'} height={'22px'} />
            <Shimmer className={'shimmer'} width={'55%'} height={'20px'} />

            <br></br>
            {/* <Shimmer width={'100%'} height={'45px'} />
            <br></br>
            <Shimmer width={'100%'} height={'45px'} />
            <br></br>
            <Shimmer width={'100%'} height={'45px'} />
            <br></br>
            <Shimmer width={'100%'} height={'45px'} /> */}
        </>
    );
};
export default MultipleShimmer;
