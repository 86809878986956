import './SettingsButton.css';

function SettingsButton({ className, children, ...rest }) {
    return (
        <button {...rest} className={`${className} settings-btn`}>
            {children}
        </button>
    );
}

export default SettingsButton;
