import { useEffect, useState } from 'react';
import SettingsButton from '../../../Shared/SettingsButton/SettingsButton';
import DeleteBankAccount from '../DeleteBankAccount/DeleteBankAccount';
import './BankAccountDetails.css';

function BankAccountDetails(props) {
    const [showModal, setShowModal] = useState(false);
    const [bankData, setBankData] = useState();

    useEffect(() => {
        if (props.data) setBankData(props.data);
    }, [props.data]);

    return (
        <>
            {showModal && (
                <DeleteBankAccount bank_id={bankData.id} onClose={() => setShowModal(false)} />
            )}

            {bankData && (
                <div className="bank-account-details">
                    <div className="details-title-area">
                        <h3>Bank account details :</h3>
                        <SettingsButton onClick={() => setShowModal(true)}>
                            Delete Account
                        </SettingsButton>
                    </div>
                    <div className="details-area">
                        <div className="account-details">
                            <h3>Account type</h3>
                            <h4>Personal</h4>
                        </div>
                        <div className="account-details">
                            <h3>Bank country</h3>
                            <h4>{bankData.country.country_name}</h4>
                        </div>
                        <div className="account-details">
                            <h3>Bank Name</h3>
                            <h4>{bankData.bank_name}</h4>
                        </div>
                        <div className="account-details">
                            <h3>Account Holder Name</h3>
                            <h4>{bankData.account_name}</h4>
                        </div>
                        <div className="account-details">
                            <h3>Account Number</h3>
                            <h4>{bankData.account_number}</h4>
                        </div>
                        <div className="account-details">
                            <h3>Branch Name</h3>
                            <h4>{bankData.branch_name}</h4>
                        </div>
                        <div className="account-details">
                            <h3>Routing Number</h3>
                            <h4>{bankData.routing_number}</h4>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BankAccountDetails;
