import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../AlertMessage/ErrorMessage';
import EmailField from '../FormFields/EmailField/EmailField';
// import SuccessMessage from '../AlertMessage/SuccessMessage';
import apiCall from 'api/apiCall';
import Loading from 'components/Loading/Loading';
import { v4 as uuidv4 } from 'uuid';
import EyeButton from '../FormFields/EyeButton/EyeButton';
import PasswordField from '../FormFields/PasswordField/PasswordField';
import SubmitButton from '../FormFields/SubmitButton/SubmitButton';
import LoginRegister from '../LoginRegister';
import { setTokens } from './Auths.js';
import Phone from './Phone/Phone';

const Login = () => {
    let deviceID = localStorage.getItem('deviceID');
    if (!deviceID) {
        deviceID = uuidv4();
        localStorage.setItem('deviceID', deviceID);
    }

    const [userData, setUserData] = useState({
        email: '',
        password: '',
        calling_code: '+880',
        phone: ''
    });

    const [showPassword, SetShowPassword] = useState(false);
    const [isEmail, setIsEmail] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [msg, setMsg] = useState();

    useEffect(() => {
        document.title = 'Sign In';
    });

    // Email validation
    const emailValidation = () => {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return !(regex.test(userData?.email) === false);
    };

    // Login function
    const login = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let res;
        if (isEmail) {
            if (!userData.email || !userData.password) {
                setIsError(true);
                return;
            }

            setIsError(false);

            res = await apiCall('/login', 'POST', {
                email: userData.email,
                password: userData.password,
                deviceID
            });
        }

        if (res.ok) {
            localStorage.setItem('user', JSON.stringify(res.data.user));
            setTokens(res.data.tokens);
            setMsg(res.msg);
            // setIsLoading(false);
            if (window.location.pathname === '/login') navigate('/');
            window.location.reload();
        } else {
            setMsg(res.msg);
            setIsError(true);
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loading />}

            <LoginRegister>
                <div className="login-register-top">
                    <h1>Sign In</h1>

                    {isEmail && (
                        <>
                            <p>Enter your email and password</p>
                        </>
                    )}
                    {!isEmail && (
                        <>
                            <p>Enter your phone number</p>
                        </>
                    )}
                </div>
                <div className="login-register-btn-area">
                    <div
                        onClick={() => {
                            setIsEmail(true);
                        }}
                        className={`login-register-btn ${isEmail ? `btn-active` : ''}`}
                    >
                        Email
                    </div>
                    <div
                        onClick={() => {
                            setIsEmail(false);
                        }}
                        className={`login-register-btn ${isEmail ? '' : 'btn-active'}`}
                    >
                        Phone
                    </div>
                </div>
                <div className="login-register-form">
                    <form onSubmit={login}>
                        {isEmail && (
                            <>
                                <EmailField
                                    onChange={(e) =>
                                        setUserData({ ...userData, email: e.target.value })
                                    } /* className={'error-field'} */
                                />
                                <div className="login-register-form-field field-password">
                                    <PasswordField
                                        value={userData.password}
                                        type={`${!showPassword ? 'password' : 'text'}`}
                                        onChange={(e) =>
                                            setUserData({ ...userData, password: e.target.value })
                                        }
                                        placeholder="Password"
                                        required
                                        /* className={'error-field'} */
                                    />
                                    <EyeButton
                                        show={showPassword}
                                        onClick={() => {
                                            SetShowPassword(!showPassword);
                                        }}
                                    />
                                </div>
                                <div className="remember-me-area">
                                    <div className="remember-me">
                                        <input type="checkbox" />
                                        <label>Remember Me</label>
                                    </div>
                                    <Link to="forgot-password" className="forgot-password">
                                        Forgot Password?
                                    </Link>
                                </div>
                                <SubmitButton
                                    type="submit"
                                    disabled={
                                        userData.email && emailValidation() && userData.password
                                            ? ''
                                            : 'disabled'
                                    }
                                    // disabled={!userData.email || !userData.password}
                                >
                                    Sign In
                                </SubmitButton>
                            </>
                        )}

                        {!isEmail && (
                            <Phone
                                userData={userData}
                                setUserData={setUserData}
                                setIsLoading={setIsLoading}
                            />
                        )}

                        <div className="bottom-bar">
                            <p>
                                Don't have an account ? <Link to="/register">Sign Up</Link>
                            </p>
                        </div>
                        {/* <SuccessMessage>{msg}</SuccessMessage>  */}
                        {msg && <ErrorMessage>{msg}</ErrorMessage>}
                    </form>
                </div>
            </LoginRegister>
        </>
    );
};

export default Login;
