import { AiOutlineBank } from 'react-icons/ai';
import { BiLock, BiLogOutCircle, BiUser } from 'react-icons/bi';
import { CgShoppingBag } from 'react-icons/cg';
import { HiOutlineDocumentText, HiPhone } from 'react-icons/hi';
import { TbChecklist } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';
import { logout } from '../../Login/Auths';

import './SettingsSidebar.css';

function SettingsSidebar() {
    return (
        <div className="settings-sidebar">
            <h3>Settings</h3>
            <div className="sidebar-main">
                <div className="sidebar-menu-area">
                    <p>Your Details</p>
                    <div className="sidebar-menu">
                        <NavLink
                            to="profile"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <BiUser />
                            </span>
                            <span className="sidbar-title">Profile</span>
                        </NavLink>
                    </div>
                    <div className="sidebar-menu">
                        <NavLink
                            to="/statement"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <TbChecklist />
                            </span>
                            <span className="sidbar-title">Statement</span>
                        </NavLink>
                    </div>
                </div>
                <div className="sidebar-menu-area">
                    <p>Portfolio link</p>
                    <div className="sidebar-menu">
                        <NavLink
                            to="portfolio"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <CgShoppingBag />
                            </span>
                            <span className="sidbar-title">Portfolio</span>
                        </NavLink>
                    </div>
                </div>
                <div className="sidebar-menu-area">
                    <p>Help 24/7</p>
                    <div className="sidebar-menu">
                        <NavLink
                            to="contact"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <HiPhone />
                            </span>
                            <span className="sidbar-title">Contact</span>
                        </NavLink>
                    </div>
                </div>
                <div className="sidebar-menu-area">
                    <p>Security</p>
                    <div className="sidebar-menu">
                        <NavLink
                            to="recover"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <BiLock />
                            </span>
                            <span className="sidbar-title">Change Password</span>
                        </NavLink>
                    </div>
                    <div className="sidebar-menu">
                        <NavLink
                            to="documents"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <HiOutlineDocumentText />
                            </span>
                            <span className="sidbar-title">Documents</span>
                        </NavLink>
                    </div>
                </div>
                <div className="sidebar-menu-area">
                    <p>Bank Account</p>
                    <div className="sidebar-menu">
                        <NavLink
                            to="bank-account"
                            className={({ isActive }) => (isActive ? 'active-class' : 'no-active')}
                        >
                            <span className="sidebar-icon">
                                <AiOutlineBank />
                            </span>
                            <span className="sidbar-title">Bank Account</span>
                        </NavLink>
                    </div>
                </div>
                <div className="sidebar-menu-area">
                    <p>Privacy</p>
                    <div className="sidebar-menu">
                        <button onClick={logout}>
                            <span className="sidebar-icon">
                                <BiLogOutCircle />
                            </span>
                            <span className="sidbar-title">Log out</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsSidebar;
