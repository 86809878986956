import React from 'react';

function SelectOption({ className, label, children, ...rest }) {
    return (
        <div className={`${className} form-input-field`}>
            <label htmlFor={label}>{label}</label>
            <select {...rest}>{children}</select>
        </div>
    );
}

export default SelectOption;
