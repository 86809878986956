import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
// import { GrNotification } from 'react-icons/gr';
import { NavLink } from 'react-router-dom';
import logo from '../../../images/logo.svg';
import notificationIcon from '../../../images/notification_icon.svg';
import './Header.css';

const Header = (props) => {
    const [name, setName] = useState();
    // const [notificationCount, setNotificationCount] = useState(10);
    let user;
    if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user'));
    }
    useEffect(() => {
        if (props.isLoggedIn && user) {
            setName(user.fullName);
        }
    }, []);
    return (
        <div className="header">
            <NavLink to="">
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
            </NavLink>

            <div className="right-nav">
                <NavLink to="/notification">
                    <div onClick={() => props.setNotificationCount(0)} className="notification">
                        <span>
                            {props.notificationCount > 0 && <p>{props.notificationCount}</p>}
                            {/* <GrNotification /> */}
                            <img src={notificationIcon} />
                        </span>
                    </div>
                </NavLink>

                <NavLink to="/settings/profile">
                    <div className="user-profile">
                        <Avatar size={50} name={name ? name : ''} className="profile-image" />
                        {/* <div className=""></div> */}

                        <div className="profile-name-icon">
                            <span className="profile-name">{name}</span>
                        </div>
                    </div>
                </NavLink>
            </div>
        </div>
    );
};

export default Header;
