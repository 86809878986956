import { Link } from 'react-router-dom';
import bankIcon from '../../../images/bank.svg';
import plusIcon from '../../../images/plus.svg';
import './AddBankAccount.css';

function AddBankAccount({ className }) {
    return (
        <div className={className}>
            <Link to="/settings/add-bank-account">
                <div className="add-bank-account">
                    <div className="bank-icon-area">
                        <div className="bank-icon-img">
                            <img src={bankIcon} alt="" />
                        </div>
                        <span className="add-bank-icon">
                            <img src={plusIcon} alt="" />
                        </span>
                    </div>
                    <h3>Add bank account</h3>
                </div>
            </Link>
        </div>
    );
}

export default AddBankAccount;
