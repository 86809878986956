import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import FullInvoice from './components/Dashboard/FullInvoice/FullInvoice';
import FullTransactions from './components/Dashboard/FullTransactions/FullTransactions';
import Home from './components/Dashboard/Home/Home/Home';
import Notification from './components/Dashboard/Notification/Notification';
import AddBankAccountForm from './components/Dashboard/Settings/AddBankAccountForm/AddBankAccountForm';
import BankAccount from './components/Dashboard/Settings/BankAccount/BankAccount';
import ChangePassword from './components/Dashboard/Settings/ChangePassword/ChangePassword';
import Contact from './components/Dashboard/Settings/Contact/Contact';
import Documents from './components/Dashboard/Settings/Documents/Documents';
import Portfolio from './components/Dashboard/Settings/Portfolio/Portfolio';
import Profile from './components/Dashboard/Settings/Profile/Profile';
import Settings from './components/Dashboard/Settings/Settings/Settings';
// import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ForgotPassword from 'components/LoginRegister/ForgotPassword/ForgotPassword';
import Loading from './components/Loading/Loading';
import { checkAccessToken, checkRefreshToken, refreshAuthToken } from './components/Login/Auths';
// import Login from './components/Login/Login';
// import SignUp from './components/SignUp/SignUp';
import Login from './components/LoginRegister/Login/Login';
import Register from './components/LoginRegister/Register/Register';
import NotFound from './components/NotFound/NotFound';
import Statement from './components/Statement/Statement';
import FirebaseNotification from './Notification';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loginCheck = async () => {
        setIsLoading(true);
        const isAccess = await checkAccessToken();
        const isRefresh = await checkRefreshToken();
        if (isAccess && isRefresh) {
            setIsLoggedIn(true);
        } else {
            const isRefreshed = await refreshAuthToken();
            if (isRefreshed) setIsLoggedIn(true);
            else setIsLoggedIn(false);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        loginCheck();
    }, []);
    const [notificationCount, setNotificationCount] = useState(0);

    const increaseNotificationCount = () => {
        setNotificationCount(() => notificationCount + 1);
    };

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && (
                <>
                    {isLoggedIn && (
                        <FirebaseNotification
                            increaseNotificationCount={increaseNotificationCount}
                        />
                    )}
                    <BrowserRouter>
                        <Routes>
                            {!isLoggedIn ? (
                                <>
                                    <Route path="*" element={<Login />} />
                                    <Route
                                        path="register"
                                        element={<Register setIsLoggedIn={setIsLoggedIn} />}
                                    />

                                    {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                                    <Route path="/forgot-password" element={<ForgotPassword />} />
                                </>
                            ) : (
                                <>
                                    <Route path="/login" element={<Navigate replace to="/" />} />
                                    <Route path="/register" element={<Navigate replace to="/" />} />
                                    <Route
                                        path=""
                                        element={
                                            <Dashboard
                                                notificationCount={notificationCount}
                                                setNotificationCount={setNotificationCount}
                                                isLoggedIn={isLoggedIn}
                                            />
                                        }
                                    >
                                        <Route path="" element={<Home />} />
                                        <Route path="/statement" element={<Statement />} />
                                        <Route path="invoice" element={<FullInvoice />} />
                                        <Route path="transactions" element={<FullTransactions />} />
                                        <Route path="/notification" element={<Notification />} />
                                        <Route path="settings" element={<Settings />}>
                                            <Route path="profile" element={<Profile />} />
                                            <Route path="recover" element={<ChangePassword />} />
                                            <Route path="documents" element={<Documents />} />
                                            <Route path="bank-account" element={<BankAccount />} />
                                            <Route path="portfolio" element={<Portfolio />} />
                                            <Route
                                                path="add-bank-account"
                                                element={<AddBankAccountForm />}
                                            />
                                            <Route path="contact" element={<Contact />} />
                                        </Route>
                                    </Route>

                                    <Route path="*" element={<NotFound />} />
                                </>
                            )}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </BrowserRouter>
                </>
            )}
        </>
    );
};

export default App;
