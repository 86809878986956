import { AiOutlineSetting } from 'react-icons/ai';
import { BiHome } from 'react-icons/bi';
import { HiOutlineBookmark } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer-bar">
            <div className="footer-nav">
                <ul>
                    <li>
                        <NavLink
                            to=""
                            end
                            className={({ isActive }) =>
                                isActive ? 'active-class' : 'no-active-class'
                            }
                        >
                            <span>
                                <BiHome className="home-icon" />
                            </span>
                            <span>Home</span>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to="transactions"
                            className={({ isActive }) =>
                                isActive ? 'active-class' : 'no-active-class'
                            }
                        >
                            <span>
                                <HiOutlineBookmark />
                            </span>
                            <span>Transactions</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="settings/profile"
                            className={({ isActive }) =>
                                isActive ? 'active-class' : 'no-active-class'
                            }
                        >
                            <span>
                                <AiOutlineSetting />
                            </span>
                            <span>Settings</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
