import moment from 'moment';
import { useEffect, useState } from 'react';
import apiCall from '../../../api/apiCall';
import deleteIcon from '../../../images/delete.svg';
// import unreadIcon from '../../../images/unread.svg';
import MultipleShimmer from './MultipleShimmer';
import './Notification.css';
import NotificationModal from './NotificationModal';
const Notification = () => {
    useEffect(() => {
        document.title = 'Revorium - Notifications';
        getNotifications();
    }, []);
    const [isChecked, setIsChecked] = useState({ check: '', id: null });
    const [checkedIDs, setCheckedIDs] = useState([]);
    const [notificationsData, setNotificationsData] = useState([0]);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [fields, setFields] = useState();

    const [showModal, setShowModal] = useState(false);
    const [dataForModal, setDataForModal] = useState();

    const [allIDs, setAllIDs] = useState([]);

    const addToCheckedIds = (id) => {
        const newArray = [...checkedIDs, id];
        setCheckedIDs(newArray);
    };
    const removeFromCheckedIds = (id) => {
        setCheckedIDs(checkedIDs.filter((checkedID) => checkedID !== id));
    };

    const getNotifications = async () => {
        setIsLoading(true);

        const response = await apiCall(`/notification?limit=25`);

        setMsg(response.msg);
        if (response.ok) {
            setNotificationsData(response.data);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    const deleteNotification = async (id) => {
        setIsLoading(true);

        const response = await apiCall(`/notification/user/${id}`, 'DELETE');

        setMsg(response.msg);
        if (response.ok) {
            setNotificationsData(() => notificationsData.filter((data) => data.id !== id));
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (notificationsData && notificationsData.length > 0) {
            let newArray = [];
            for (let i = 0; i < notificationsData.length; i++) {
                newArray.push(notificationsData[i].id);
            }
            setAllIDs(newArray);
            setFields(
                notificationsData.map((data, i) => (
                    <div
                        key={i}
                        className={`notification-box ${
                            isChecked.check && isChecked.id === data.id ? 'notification-active' : ''
                        }`}
                    >
                        <div className="notification-main-content">
                            <div key={i}>
                                <input
                                    key={i}
                                    className="notification-checkbox"
                                    checked={checkedIDs.includes(data.id)}
                                    onChange={(e) => {
                                        if (e.target.checked) addToCheckedIds(data.id);
                                        else removeFromCheckedIds(data.id);
                                    }}
                                    type="checkbox"
                                />
                            </div>
                            <div>
                                <h4
                                    onClick={() => {
                                        setShowModal(true);
                                        setDataForModal(data);
                                    }}
                                >
                                    {data.title}
                                </h4>
                                <p>
                                    {moment(new Date(data.updatedAt)).calendar()}
                                    {/* {new Date(data.updatedAt).toLocaleString('en-US', {})} */}
                                </p>
                            </div>
                        </div>
                        <div className="delete-btn-div">
                            {data.user_id && (
                                <img
                                    src={deleteIcon}
                                    alt=""
                                    onClick={() => deleteNotification(data.id)}
                                />
                            )}
                        </div>
                    </div>
                ))
            );
        }
    }, [notificationsData, checkedIDs]);

    return (
        <div className="notification-area">
            {showModal && dataForModal && (
                <NotificationModal data={dataForModal} onClose={() => setShowModal(false)} />
            )}
            <div className="notification-header">
                <div className="notification-left">
                    <div className={`all-notification-check`}>
                        <input
                            className="notification-checkbox"
                            type="checkbox"
                            checked={checkedIDs.length === notificationsData.length}
                            onChange={(e) => {
                                if (e.target.checked) setCheckedIDs(allIDs);
                                else setCheckedIDs([]);
                            }}
                        />
                        <label htmlFor="">Notification</label>
                    </div>
                </div>
                {/* <div className="notification-right">
                    {checkedIDs.length > 0 ? (
                        <div className="unread-delete-area">
                            <div className="mark-unread">
                                <span>
                                    <img src={unreadIcon} alt="" />
                                </span>
                                <span>Mark as unread</span>
                            </div>
                            <div className="notification-delete">
                                <span>
                                    <img src={deleteIcon} alt="" />
                                </span>
                                <span>Delete</span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="show-unread-area">
                        <div className="show-unread-title">
                            <span>Show only unread</span>
                        </div>
                        <div>
                            <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="notification-main">
                {isLoading ? (
                    <>
                        <MultipleShimmer />
                        <MultipleShimmer />
                        <MultipleShimmer />
                        <MultipleShimmer />
                    </>
                ) : (
                    <>{fields}</>
                )}
            </div>
        </div>
    );
};

export default Notification;
