import {
    checkAccessToken,
    checkRefreshToken,
    getAccessToken,
    refreshAuthToken
} from '../components/Login/Auths';

const getToken = async () => {
    const isAccess = await checkAccessToken();
    const isRefresh = await checkRefreshToken();
    if (!isAccess || !isRefresh) {
        const isRefreshed = await refreshAuthToken();
        if (!isRefreshed) await refreshAuthToken();
    }
    return await getAccessToken();
};

export default getToken;
