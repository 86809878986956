import { useEffect, useState } from 'react';
import apiCall from '../../../../api/apiCall';
import Loading from '../../../Loading/Loading';
import { getAccessToken } from '../../../Login/Auths';
import Checkbox from '../../../Shared/Form/Checkbox/Checkbox';
import Form from '../../../Shared/Form/Form';
import SelectOption from '../../../Shared/Form/SelectOption/SelectOption';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import SettingsButton from '../../../Shared/SettingsButton/SettingsButton';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import './AddBankAccountForm.css';

function AddBankAccountForm() {
    const bankList = [
        'AB bank limited',
        'Agrani Bank Limited',
        'Al - Arafah Islami Bank Limited',
        'Bangladesh Commerce Bank Limited',
        'Bangladesh Krishi Bank',
        'Bank Al - falah Limited',
        'Bank Asia Limited',
        'Basic Bank Limited',
        'BRAC Bank Limited',
        'Commercial bank of Ceylon',
        'Dhaka Bank Limited',
        'Dutch - Bangla Bank Limited',
        'Eastern Bank Limited',
        'EXIM Bank Limited',
        'First Security Islami Bank Limited',
        'Habib Bank Limited',
        'HSBC',
        'ICB Islamic Bank Limited',
        'IFIC Bank Limited',
        'Islami Bank Bangladesh Limited',
        'Jamuna Bank Limited',
        'Janata bank Limited',
        'Meghna Bank Limited',
        'Mercantile Bank Limited',
        'Midland Bank Limited',
        'Modhumoti Bank Limited',
        'Mutual Trust Bank Limited',
        'National Bank Limited',
        'NCC Bank Limited',
        'NRB Bank Limited',
        'NRB Commercial Bank Limited',
        'NRB Global Bank Limited',
        'NRB Commercial Bank Limited',
        'One Bank Limited',
        'Prime Bank Limited',
        'Pubali Bank Limited',
        'SBAC Bank Limited',
        'Shahjalal Islami Bank Limited',
        'Social Islami Bank Limited',
        'Sonali Bank Limited',
        'Southeast Bank Limited',
        'Standard Bank Limited',
        'Standard Chartered Bank Limited',
        'State Bank of India',
        'The City Bank Limited',
        'Trust Bank Limited',
        'Union Bank Limited',
        'United Commercial Bank Limited',
        'Uttara Bank Limited',
        'Woori Bank'
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [bankDetails, setBankDetails] = useState({
        country_id: 18
    });
    const [confirmed, setConfirmed] = useState(false);

    const [msg, setMsg] = useState();
    const token = getAccessToken();
    useEffect(() => {
        document.title = 'Revorium - Add Bank Account';
    }, []);

    const addBankAccount = async (e) => {
        e.preventDefault();
        setMsg('');

        setIsLoading(true);

        const response = await apiCall(`/bank`, 'POST', bankDetails);

        if (response.ok) {
            setMsg(response.msg);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && (
                <>
                    <SettingsTitle title={'Add Bank Account'} />
                    <div className="add-bank-account-form">
                        {msg && <p>{msg}</p>}
                        <Form onSubmit={addBankAccount}>
                            <SelectOption
                                label={'Bank Name'}
                                name="bank-name"
                                onChange={(e) =>
                                    setBankDetails({
                                        ...bankDetails,
                                        bank_name: e.target.value
                                    })
                                }
                                required
                            >
                                <option value={''}>Select Bank</option>
                                {bankList.map((data, i) => (
                                    <option value={data}>{data}</option>
                                ))}
                                {/* <option value="AB Bank Limited">AB Bank Limited</option>
                                <option value="Dutch Bangla Bank">Dutch Bangla Bank</option>
                                <option value="BRAC Bank Limited">BRAC Bank Limited</option>
                                <option value="Islami Bank Limited">Islami Bank Limited</option>
                                <option value="Trust Bank Limited">Trust Bank Limited</option>
                                <option value="The City Bank Limited">The City Bank Limited</option> */}
                            </SelectOption>
                            <TextInput
                                type="text"
                                label={'Branch Name'}
                                onChange={(e) =>
                                    setBankDetails({
                                        ...bankDetails,
                                        branch_name: e.target.value
                                    })
                                }
                                required
                            />
                            <TextInput
                                type="text"
                                label={'Account Holder Name'}
                                onChange={(e) =>
                                    setBankDetails({
                                        ...bankDetails,
                                        account_name: e.target.value
                                    })
                                }
                                required
                            />
                            <TextInput
                                type="number"
                                label={'Account Number'}
                                onChange={(e) =>
                                    setBankDetails({
                                        ...bankDetails,
                                        account_number: e.target.value
                                    })
                                }
                                required
                            />
                            <TextInput
                                type="text"
                                label={'Routing Number'}
                                onChange={(e) =>
                                    setBankDetails({
                                        ...bankDetails,
                                        routing_number: e.target.value
                                    })
                                }
                                required
                            />
                            <Checkbox
                                label={'I confirm the bank account details above'}
                                onChange={(e) => {
                                    setConfirmed(e.target.checked);
                                }}
                            />
                            <div className="add-bank-account-btn">
                                <SettingsButton disabled={!confirmed} type="submit">
                                    Add Bank Account
                                </SettingsButton>
                            </div>
                        </Form>
                    </div>
                </>
            )}
        </>
    );
}

export default AddBankAccountForm;
