import emptyIcon from 'images/empty.svg';
import './NoDataFound.css';
const NoDataFound = ({ data = 'Data' }) => {
    return (
        <div className="no-data-found">
            {/* <p>No {data} Found!</p> */}
            <img src={emptyIcon}></img>
        </div>
    );
};

export default NoDataFound;
