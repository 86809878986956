import { useEffect, useState } from 'react';

import { toast, ToastContainer } from 'react-toastify';
import apiCall from '../../../../api/apiCall';
import Loading from '../../../Loading/Loading';
import Button from '../../../Shared/Form/Button/Button';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import './ChangePassword.css';
import EyeButton from './EyeButton';
function ChangePassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [passwordData, setPasswordData] = useState({});
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [msg, setMsg] = useState();

    useEffect(() => {
        document.title = 'Revorium -  Change Password';
    }, []);
    const changePassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await apiCall(`/user`, 'PATCH', {
            password: passwordData.current,
            new_password: passwordData.new,
            confirm_password: passwordData.confirm
        });

        if (response.ok) {
            toast.success('The password has changed successfully', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } else {
            toast.error('Something went wrong', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            // setMsg(responseData.message);
        }

        setIsLoading(false);
    };

    const passWordValidator = (password) => {
        if (password.length < 8) {
            setMsg('Password Must be at least 8 characters long');
            return false;
        } else if (!password.match(/\d/) || !password.match(/[a-zA-Z]/)) {
            setMsg('Password Must Contain one number and one letter');
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        setIsPasswordValid(false);
        setMsg('');
        if (passwordData.new) passWordValidator(passwordData.new);
        if (passwordData.confirm) {
            if (passwordData.new !== passwordData.confirm) setMsg('Password Did not match');
        }
        if (passwordData.new && passwordData.confirm && passwordData.current) {
            if (passWordValidator(passwordData.new) && passwordData.new === passwordData.confirm) {
                setIsPasswordValid(true);
            }
        }
    }, [passwordData.new, passwordData.confirm, passwordData.password]);

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <SettingsTitle title={'Change Password'} />
                <div className="view-profile-area">
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    {msg && <p>{msg}</p>}
                    <div className="view-profile">
                        <Form onSubmit={changePassword} className={'change-password-form'}>
                            <div className="form-password-area">
                                <TextInput
                                    className={'change-password-form-input-field'}
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    label="Current Password"
                                    value={passwordData.current}
                                    required
                                    onChange={(e) => {
                                        setPasswordData({
                                            ...passwordData,
                                            current: e.target.value
                                        });
                                    }}
                                />
                                <EyeButton
                                    show={showCurrentPassword}
                                    onClick={() => {
                                        setShowCurrentPassword(!showCurrentPassword);
                                    }}
                                />
                            </div>
                            <br></br>
                            <div className="form-password-area">
                                <TextInput
                                    className={'change-password-form-input-field'}
                                    type={showNewPassword ? 'text' : 'password'}
                                    label="New Password"
                                    required
                                    value={passwordData.new}
                                    onChange={(e) => {
                                        setPasswordData({
                                            ...passwordData,
                                            new: e.target.value
                                        });
                                    }}
                                />
                                <EyeButton
                                    show={showNewPassword}
                                    onClick={() => {
                                        setShowNewPassword(!showNewPassword);
                                    }}
                                />
                            </div>
                            <br></br>
                            <div className="form-password-area">
                                <TextInput
                                    className={'change-password-form-input-field'}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    label="Confirm Password"
                                    required
                                    value={passwordData.confirm}
                                    onChange={(e) => {
                                        setPasswordData({
                                            ...passwordData,
                                            confirm: e.target.value
                                        });
                                    }}
                                />
                                <EyeButton
                                    show={showConfirmPassword}
                                    onClick={() => {
                                        setShowConfirmPassword(!showConfirmPassword);
                                    }}
                                />
                            </div>
                            <br></br>
                            <Button type="submit" disabled={!isPasswordValid}>
                                Change Password
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
