import chadShape from '../../images/chad-shape.svg';
import circleBottom from '../../images/circle-bottom.svg';
import circle from '../../images/circle.svg';
import crossLine from '../../images/cross-line.svg';
import dots from '../../images/dots.svg';
import logo from '../../images/logo2.svg';
import shapeIcon from '../../images/shapeIcon.svg';
import './LoginRegister.css';

const LoginRegister = ({ children }) => {
    return (
        <>
            <div className="login-register-form-page">
                <div className="right-top-cross-line">
                    <img src={crossLine} alt="" />
                </div>
                <div>
                    <div className="login-logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="left-top-circle">
                        <img src={circle} alt="" />
                    </div>
                    <div className="login-register-main">
                        <div className="right-top-corner">
                            <img src={chadShape} alt="" />
                        </div>
                        <div className="login-register-box">{children}</div>
                        <div className="left-bottom-corner">
                            <img src={circleBottom} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="left-bottom-dots">
                <img src={dots} alt="" />
            </div>
            <div className="right-bottom-shape">
                <img src={shapeIcon} alt="" />
            </div>
        </>
    );
};

export default LoginRegister;
