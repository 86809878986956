import React, { useState } from 'react';
import arrowIcon from '../../../../images/arrow-down.svg';
import bankIcon from '../../../../images/bank-icon.svg';
import BankAccountDetails from '../BankAccountDetails/BankAccountDetails';
import './SingleBankAccount.css';

const SingleBankAccount = ({ data }) => {
    const [viewInvoice, setViewInvoice] = useState(false);
    const [closingTable, setClosingTable] = useState(false);
    const [rotateIcon, setRotateIcon] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [msg, setMsg] = useState();

    const closeInvoiceBox = () => {
        setClosingTable(true);
        setTimeout(() => {
            setViewInvoice(false);
            setRotateIcon(!rotateIcon);
        }, 185);
    };

    const openInvoiceBox = () => {
        setViewInvoice(true);
        setClosingTable(false);
        setRotateIcon(!rotateIcon);
    };
    return (
        <>
            <div className={closingTable ? 'close-invoice-table' : 'view-invoice-box'}>
                <div
                    className="view-invoice"
                    onClick={() => (viewInvoice ? closeInvoiceBox() : openInvoiceBox())}
                >
                    <div className="view-bank-icon">
                        <div>
                            <img src={bankIcon} alt="" />
                        </div>
                        <span>
                            {data.bank_name + '....' + String(data.account_number).slice(-4)}
                        </span>
                    </div>
                    <span>
                        <img
                            className={!rotateIcon ? 'close-invoice-icon' : 'open-invoice-icon'}
                            src={arrowIcon}
                            alt=""
                        />
                    </span>
                </div>

                {viewInvoice && (
                    <div className="view-invoice-table">
                        <BankAccountDetails data={data} />
                    </div>
                )}
            </div>
        </>
    );
};
export default SingleBankAccount;
