import { Outlet } from 'react-router-dom';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header/Header';
import './Dashboard.css';

const Dashboard = (props) => {
    return (
        <>
            <div className="dashboard-header">
                <Header
                    notificationCount={props.notificationCount}
                    setNotificationCount={props.setNotificationCount}
                    isLoggedIn={props.isLoggedIn}
                />
            </div>
            <div className="main">
                <div className="container">
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default Dashboard;
