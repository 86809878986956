import apiCall from 'api/apiCall';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import { getAccessToken } from '../../Login/Auths';
import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import RequestMoney from '../Home/RequestMoney/RequestMoney';
import './FullInvoice.css';
const FullInvoice = () => {
    const [showModal, setShowModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [tooltipId, setTooltipId] = useState(null);

    const [msg, setMsg] = useState();
    const location = useLocation();
    const [searchKey, setSearchKey] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [searchedData, setSearchedData] = useState();
    const [data, setData] = useState();
    const token = getAccessToken();
    useEffect(() => {
        document.title = 'Revorium - FullInvoice';
    });
    const getInvoices = async () => {
        setIsLoading(true);

        const response = await apiCall('/invoices');
        setMsg(response.msg);

        if (response.ok) {
            setData(response.data);
            setMsg('Earnigs Get Successful');
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        if (searchedData) {
            setInvoiceData(
                searchedData.map((data, i) => (
                    <tr
                        key={data.id}
                        className={
                            i % 2 === 0
                                ? 'odd-column tr-color single-invoice'
                                : 'tr-color single-invoice'
                        }
                    >
                        <td
                            onClick={() => {
                                setTooltipId(data.id);
                                navigator.clipboard.writeText(
                                    process.env.REACT_APP_REVO_PAYMENT_URL + data.invoice_id
                                );
                            }}
                        >
                            <div className="tooltip">
                                <span>
                                    <AiOutlineCopy />
                                </span>
                                <span>{data.invoice_id}</span>
                                {tooltipId === data.id ? (
                                    <span className="tooltiptext">Copied</span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </td>
                        <td>
                            {moment(new Date(data.createdAt), 'MMDDYYYY').format(
                                'Do MMM YYYY, h:mm:ss a'
                            )}
                        </td>
                        <td>{data.client_email}</td>
                        <td>${data.amount.toFixed(2)}</td>
                        <td>
                            <span
                                className={
                                    data.isPaid
                                        ? 'paid'
                                        : new Date() > new Date(data.due_date)
                                        ? 'expired'
                                        : 'pending'
                                }
                            >
                                {data.isPaid
                                    ? 'Paid'
                                    : new Date() > new Date(data.due_date)
                                    ? 'Expired'
                                    : 'Pending'}
                            </span>
                        </td>
                    </tr>
                ))
            );
        }
    }, [searchedData]);

    useEffect(() => {
        if (data && searchKey) {
            setSearchedData(
                data.filter((data) =>
                    data.invoice_id.toLowerCase().includes(searchKey.toLowerCase())
                )
            );
        } else setSearchedData(location.state);
    }, [searchKey]);
    useEffect(() => {
        getInvoices();
    }, []);

    useEffect(() => {
        setSearchedData(data);
    }, [data]);

    return (
        <>
            {showModal && <RequestMoney onClose={() => setShowModal(false)} />}

            <div className="invoice-full-area">
                <div className="invoice-top">
                    <div className="invoice-title-btn-area">
                        <div className="invoice-title">
                            <h3>Invoice</h3>
                            <p>List of all your recent invoices</p>
                        </div>
                        <div className="search-area">
                            <div className="search">
                                <span>
                                    <BiSearch />
                                </span>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => setSearchKey(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <button onClick={() => setShowModal(true)} className="btn-invoice">
                                Request Money
                            </button>
                        </div>
                    </div>
                </div>
                <div className="invoice-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Invoice ID</th>
                                <th>Date</th>
                                <th>Email</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {!isLoading && <tbody>{invoiceData && invoiceData}</tbody>}
                    </table>
                    {!isLoading && invoiceData && invoiceData.length === 0 && <NoDataFound />}
                    {isLoading && (
                        <>
                            <Shimmer width={'100%'} height={'50px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'50px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'50px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'50px'} />
                            <br></br>
                            <Shimmer width={'100%'} height={'50px'} />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default FullInvoice;
