import { useEffect, useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { HiOutlineArrowSmDown } from 'react-icons/hi';
import { Shimmer } from 'react-shimmer';
// import NoDataFound from '../../Shared/NoDataFound/NoDataFound';
import apiCall from 'api/apiCall';
import NoDataFound from 'components/Shared/NoDataFound/NoDataFound';
import moment from 'moment';
import InvoicePayIcon from '../../../images/InvoicePayTnx.svg';
import LinkPayIcon from '../../../images/LinkPayTnx.svg';
import ReceiveTransactionModal from '../Home/TransactionModal/ReceiveTransactionModal';
import WithdrawTransactionModal from '../Home/TransactionModal/WithdrawTransactionModal';
import './FullTransactions.css';
function FullTransactions() {
    const [isSwap, setIsSwap] = useState(true);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        document.title = 'Revorium - FullTransactions';
    });
    const [receivedTransactionsData, setReceivedTransactionsData] = useState();
    const [withdrawTransactionsData, setWithdrawTransactionsData] = useState();
    const [dataForModal, setDataForModal] = useState();
    const [msg, setMsg] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const getReceivedTransactions = async () => {
        setIsLoading(true);

        const response = await apiCall(`/withdraw/received_transactions`);

        if (response.ok) {
            setReceivedTransactionsData(response.data);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    const getWithdrawTransactions = async () => {
        setIsLoading(true);

        const response = await apiCall(`/withdraw/withdraw_transactions`);

        if (response.ok) {
            setWithdrawTransactionsData(response.data);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        getReceivedTransactions();
        getWithdrawTransactions();
    }, []);

    const [fields, setFields] = useState(false);

    useEffect(() => {
        let data;
        isSwap
            ? receivedTransactionsData
                ? (data = receivedTransactionsData)
                : (data = [])
            : withdrawTransactionsData
            ? (data = withdrawTransactionsData)
            : (data = []);

        if (data) {
            let i = 0;
            setFields(
                data.map((data, i) => (
                    <tr
                        onClick={() => {
                            setShowModal(true);
                            setDataForModal(data);
                        }}
                        className={
                            i % 2 === 0 ? 'odd-column single-transaction' : 'single-transaction'
                        }
                        key={data.id}
                    >
                        <td>
                            <div className="full-transaction-data">
                                <div className="download-icon">
                                    <span>
                                        {isSwap ? (
                                            data.payment.invoice ? (
                                                <img src={InvoicePayIcon} />
                                            ) : (
                                                <img src={LinkPayIcon} />
                                            )
                                        ) : (
                                            <BiDownload />
                                        )}
                                    </span>
                                </div>
                                <div className="transaction-data-title">
                                    <h4>
                                        {isSwap
                                            ? data.payment.invoice
                                                ? data.payment.invoice.client_email
                                                : data.payment.link_payment.payer_email
                                            : data.withdraw.bank_name +
                                              '....' +
                                              String(data.withdraw.account_number).slice(-4)}
                                    </h4>
                                </div>
                            </div>
                        </td>
                        <td>
                            {moment(new Date(data.createdAt), 'MMDDYYYY').format(
                                'Do MMM YYYY, h:mm:ss a'
                            )}
                        </td>
                        <td>
                            <div className="transaction-amount">
                                <span>
                                    $
                                    {isSwap
                                        ? data.amount_in.toFixed(2)
                                        : data.withdraw.withdraw_amount.toFixed(2)}
                                </span>
                            </div>
                        </td>
                        <td
                            className={`transaction-status transaction-status-${data.status.toLocaleLowerCase()}`}
                        >
                            {data.status}
                        </td>
                    </tr>
                ))
            );
        }
    }, [isSwap, receivedTransactionsData, withdrawTransactionsData]);

    return (
        <>
            {!isSwap && showModal && dataForModal && (
                <WithdrawTransactionModal
                    dataForModal={dataForModal}
                    onClose={() => setShowModal(false)}
                />
            )}
            {isSwap && showModal && dataForModal && (
                <ReceiveTransactionModal
                    dataForModal={dataForModal}
                    onClose={() => setShowModal(false)}
                />
            )}
            <div className="full-transaction">
                <div className="transaction-top">
                    <div className="transaction-title-area">
                        <div className="transaction-selct-area">
                            <div className="transaction-title">
                                <h3>Transactions</h3>
                            </div>
                            {/* <div className="transaction-select">
                                <select name="" id="">
                                    <option value="">Latest</option>
                                    <option value="">Old</option>
                                </select>
                            </div> */}
                        </div>

                        <div className="select-swap-btn-area">
                            <div className="swap-btn-area">
                                <button
                                    onClick={() => setIsSwap(true)}
                                    className={`swap-btn ${isSwap ? 'transaction-btn' : ''}`}
                                >
                                    <span>
                                        <BiDownload />
                                    </span>
                                    <span>Received</span>
                                </button>
                                <button
                                    onClick={() => setIsSwap(false)}
                                    className={`swap-btn ${isSwap ? '' : 'transaction-btn'}`}
                                >
                                    <span>
                                        <HiOutlineArrowSmDown />
                                    </span>
                                    <span>Withdraw</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="transaction-wrapper">
                        <div className="transaction-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>

                                {!isLoading && (
                                    <>
                                        <tbody>{fields && fields}</tbody>
                                    </>
                                )}
                            </table>

                            {isLoading && (
                                <>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                </>
                            )}
                        </div>
                        {!isLoading && fields && fields.length === 0 && <NoDataFound />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FullTransactions;
