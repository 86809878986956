import { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import apiCall from '../../../../api/apiCall';
import Loading from '../../../Loading/Loading';
import { getAccessToken } from '../../../Login/Auths';
import Modal from '../../../Shared/Modal/Modal';
import SettingsButton from '../../../Shared/SettingsButton/SettingsButton';
import './DeleteBankAccount.css';

const DeleteBankAccount = ({ bank_id, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [msg, setMsg] = useState();
    const token = getAccessToken();
    useEffect(() => {
        document.title = 'Revorium - Add Bank Account';
    }, []);

    const deleteBank = async (e) => {
        e.preventDefault();
        setMsg('');

        setIsLoading(true);

        const response = await apiCall(`/bank/${bank_id}`, 'DELETE');

        if (response.ok) {
            // setBankData(responseData.data);
            window.location.reload();
            setMsg(response.msg);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };
    return (
        <Modal className={'delete-modal'} onClose={() => onClose()}>
            {msg && <p>{msg}</p>}
            {isLoading && <Loading />}
            {!isLoading && (
                <div className="delete-bank-account">
                    <div className="close-icon">
                        <GrClose onClick={() => (onClose ? onClose() : '')} />
                    </div>
                    <h2>Bank Account Delete</h2>
                    <p>Are you sure? Having a Bank Account helps others recognize you.</p>
                    <div className="delete-bank-account-btn">
                        <SettingsButton onClick={deleteBank}>Delete</SettingsButton>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default DeleteBankAccount;
