import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import bankIcon from '../../../../images/bank-icon2.svg';
import { getAccessToken } from '../../../Login/Auths';
import NoDataFound from '../../../Shared/NoDataFound/NoDataFound';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import './BankAccount.css';
import SingleBankAccount from './SingleBankAccount';

function BankAccount() {
    const [isShow, setIsShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bankData, setBankData] = useState([]);
    const [bankDataFields, setBankDataFields] = useState();
    const [isMaxBank, setIsMaxBank] = useState(false);

    const [msg, setMsg] = useState();
    const token = getAccessToken();
    useEffect(() => {
        document.title = 'Revorium -  Bank Account';
        getUserBanks();
    }, []);

    const getUserBanks = async () => {
        setIsLoading(true);

        const response = await apiCall(`/bank`);

        if (response.ok) {
            setBankData(response.data);
            setMsg(response.msg);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (bankData) {
            if (bankData.length > 1) {
                setIsMaxBank(true);
            }

            if (bankData.length === 0) {
                setBankDataFields('No Banks Found. Please Add One');
            } else {
                setBankDataFields(
                    bankData.map((data) => (
                        <>
                            <SingleBankAccount key={data.id} data={data} />
                            <br></br>
                        </>
                    ))
                );
            }
        }
    }, [bankData]);

    return (
        <>
            <SettingsTitle title={'Bank Account'} />

            {!isMaxBank && (
                <>
                    <Link to="/settings/add-bank-account">
                        <div className="view-invoice-box">
                            <div className="view-invoice">
                                <div className="view-bank-icon">
                                    <div>
                                        <img src={bankIcon} alt="" />
                                    </div>
                                    <span>Add bank account</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <br />
                </>
            )}

            <>
                {isLoading ? (
                    <>
                        <Shimmer className="" width={'100%'} height={'45px'} />
                        <br></br>
                        <Shimmer className="" width={'100%'} height={'45px'} />
                    </>
                ) : bankDataFields ? (
                    bankDataFields
                ) : (
                    <NoDataFound data="bank" />
                )}
            </>
        </>
    );
}

export default BankAccount;
