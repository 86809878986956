// import {
//     checkAccessToken,
//     checkRefreshToken,
//     getAccessToken,
//     refreshAuthToken
// } from '../components/Login/Auths';
import getToken from './getToken';
const baseUrl = process.env.REACT_APP_API_URL;
// const loginCheck = async () => {
//     const isAccess = await checkAccessToken();
//     const isRefresh = await checkRefreshToken();
//     if (isAccess && isRefresh) {
//         return true;
//     } else {
//         const isRefreshed = await refreshAuthToken();
//         if (isRefreshed) return true;
//         else return false;
//     }
// };

const apiCall = async (endPoint, method = 'GET', data = []) => {
    try {
        const response = await fetch(`${baseUrl}${endPoint}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + (await getToken())
            },
            body: method === 'GET' ? null : JSON.stringify(data)
        });
        const responseData = await response.json();

        if (response.ok) {
            return {
                ok: true,
                data: responseData.data,
                msg: responseData.message
            };
        } else {
            return {
                ok: false,
                msg: responseData.message
            };
        }
    } catch (err) {
        return {
            ok: false,
            msg: err.message
        };
    }
};
export default apiCall;
