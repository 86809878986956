import SubmitButton from 'components/LoginRegister/FormFields/SubmitButton/SubmitButton';
import React, { useState } from 'react';
import { COUNTRIES } from './Countries';
import CountrySelector from './CountrySelector';
const Country = ({ setPageState, setUserData, userData }) => {
    const myRef = React.createRef();
    const [isOpen, setIsOpen] = useState(false);
    // const [country, setCountry] = useState('BD');

    //Event handler
    const handleSubmit = (e) => {
        e.preventDefault();
        setPageState('password');
    };

    return (
        <form onSubmit={handleSubmit}>
            <CountrySelector
                id={'countries'}
                ref={myRef}
                open={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
                onChange={(val) => setUserData({ ...userData, country_code: val })}
                selectedValue={COUNTRIES.find((option) => option.value === userData.country_code)}
                // onChange={(val) => setCountry(val)}
                // selectedValue={COUNTRIES.find((option) => option.value === country)}
            />

            <SubmitButton type="submit" disabled={userData?.country_code ? '' : 'disabled'}>
                Next
            </SubmitButton>
        </form>
    );
};

export default Country;
