import SettingsButton from '../SettingsButton/SettingsButton';
import './DocumentSelect.css';

function DocumentSelect({ title, subtitle, status, onClick }) {
    const statusClass = status ? status.toLowerCase() : '';

    return (
        <div className={`document-select`}>
            <div className="document-select-left">
                <h3>
                    {title} {status && <span className={`status-${statusClass}`}>({status})</span>}
                </h3>
                <p>{subtitle}</p>
            </div>
            {status === 'Pending' || status === 'Approved' ? (
                ''
            ) : (
                <div className="document-select-right">
                    <SettingsButton onClick={onClick} className={'upload-btn'}>
                        Upload
                    </SettingsButton>
                </div>
            )}
        </div>
    );
}

export default DocumentSelect;
