import { useEffect, useState } from 'react';
import { HiOutlineArrowSmDown } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import Withdraw from '../Withdraw/Withdraw';
import './EarningCard.css';
import Info from './Info';
import InfoIcon from './info.svg';
const EarningCard = (props) => {
    const navigate = useNavigate();
    const [isVerified, setIsVerified] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [earnings, setEarnings] = useState({
        settlement: 0,
        pending: 0,
        balance: 0
    });
    const updateBalance = (withdrawBalance) => {
        setEarnings({
            ...earnings,
            balance: earnings.balance - withdrawBalance,
            pending: earnings.pending + withdrawBalance
        });
    };

    useEffect(() => {
        if (props.userData) setIsVerified(props.userData.isVerified);
    }, [props]);
    useEffect(() => {
        if (!props.data) return;
        setEarnings({
            settlement: props.data.settlement,
            pending: props.data.pending,
            balance: props.data.balance
        });
    }, [props.data]);

    return (
        <>
            {showModal && (
                <Withdraw
                    updateBalance={updateBalance}
                    balance={earnings.balance}
                    onClose={() => setShowModal(false)}
                />
            )}

            <div className="earning-card">
                <div className="card-amount-area">
                    <div className="card-amount">
                        {!props.isLoading && (
                            <>
                                <p>Total Earnings</p>
                                <h3>${earnings.balance.toFixed(2)}</h3>
                            </>
                        )}
                        {props.isLoading && <Shimmer width={'150px'} height={'66px'} />}
                    </div>
                    <div className="card-draft">
                        {/* <h3>${earnings.balance.toFixed(2)}</h3> */}
                        {!props.isLoading && (
                            <>
                                <p>
                                    <span>
                                        Settlement
                                        <button
                                            onClick={() => setShowInfo(!showInfo)}
                                            className="info-icon"
                                        >
                                            <img src={InfoIcon} alt="info-icon" />
                                        </button>
                                    </span>
                                </p>
                                <h3>${earnings.settlement.toFixed(2)}</h3>
                                {showInfo && <Info onClose={() => setShowInfo(false)} />}
                            </>
                        )}
                        {props.isLoading && <Shimmer width={'120px'} height={'53px'} />}
                    </div>
                </div>
                <div className="pending-draft-area">
                    <div className="card-pending">
                        {!props.isLoading && (
                            <>
                                <p>
                                    <span>Pending</span>
                                </p>
                                <h3>${earnings.pending.toFixed(2)}</h3>
                            </>
                        )}
                        {props.isLoading && <Shimmer width={'120px'} height={'53px'} />}
                    </div>
                    <div
                        onClick={() =>
                            isVerified ? setShowModal(true) : navigate('/settings/documents')
                        }
                    >
                        <button className="btn-withdraw">
                            <span>
                                <HiOutlineArrowSmDown />
                            </span>
                            <span>{isVerified ? 'Withdraw' : 'Verify to Withdraw'}</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EarningCard;
