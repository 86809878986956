import moment from 'moment';
import { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import logo from '../../../../images/logo.svg';
import Modal from '../../../Shared/Modal/Modal';
import './ReceiveTransactionModal.css';
const ReceiveTransactionModal = ({ dataForModal, onClose }) => {
    const [transactionStatus, setTransactionStatus] = useState(dataForModal.status);
    let itemJSX;
    if (dataForModal.payment.invoice) {
        if (dataForModal.payment.invoice.items.length > 0) {
            itemJSX = dataForModal.payment.invoice.items.map((data, i) => (
                <>
                    <div className="items-details">
                        <h3>
                            {i + 1}: {data.name}
                        </h3>
                        <h3>${data.quantity * data.price}</h3>
                    </div>
                    <div className="items-details">
                        <h3></h3>
                        <h3 className="quantity-price">
                            {data.quantity} X ${data.price} = ${data.quantity * data.price}
                        </h3>
                    </div>
                </>
            ));
            itemJSX = (
                <>
                    {itemJSX}
                    <hr></hr>
                    <div className="withdraw-transaction-details">
                        <h3>Total Amount</h3>
                        <h3>${dataForModal.payment.invoice.amount}</h3>
                    </div>
                </>
            );
        }
    }
    return (
        <Modal className={'modal-sidebar'} onClose={() => onClose()}>
            <div className="modal-sidebar-content">
                <div className="withdraw-top">
                    <div className="close-icon">
                        <GrClose onClick={() => (onClose ? onClose() : '')} />
                    </div>

                    <div className="receive-top-amount">
                        <div>
                            <img src={logo}></img>
                        </div>
                        <div>
                            <h2>{dataForModal.amount_in.toFixed(2)} USD</h2>
                            {moment(new Date(dataForModal.createdAt)).format(
                                'Do MMM YYYY, h:mm:ss a'
                            )}
                        </div>
                    </div>
                </div>
                <div className="withdraw-transaction-details-area">
                    <h2>Transaction Details</h2>
                    <div className={` withdraw-transaction-details }`}>
                        <h3>Transaction Status</h3>
                        <div
                            className={`transaction-status-${transactionStatus.toLocaleLowerCase()}`}
                        >
                            {transactionStatus}
                        </div>
                    </div>
                    <div className="withdraw-transaction-details">
                        <h3>Transaction ID</h3>
                        <h3>{dataForModal.transaction_id}</h3>
                    </div>

                    <div className="withdraw-transaction-details">
                        <h3>Received BY</h3>
                        <h3>{dataForModal.payment.invoice ? 'Invoice' : 'LinkPay'}</h3>
                    </div>
                    <div className="withdraw-transaction-details">
                        <h3>Receive Amount (USD)</h3>
                        <h3>${dataForModal.amount_in.toFixed(2)}</h3>
                    </div>
                    {dataForModal.payment.invoice && (
                        <div className="withdraw-transaction-details">
                            <h3>Client Name</h3>
                            <h3>{dataForModal.payment.invoice.client_name}</h3>
                        </div>
                    )}

                    <div className="withdraw-transaction-details">
                        <h3>{dataForModal.payment.invoice ? 'Client Email' : 'Payer Email'}</h3>
                        <h3>
                            {dataForModal.payment.invoice
                                ? dataForModal.payment.invoice.client_email
                                : dataForModal.payment.link_payment.payer_email}
                        </h3>
                    </div>
                    {dataForModal.payment.link_payment && (
                        <div className="withdraw-transaction-desc">
                            <h3>Note From Payer</h3>
                            <p>{dataForModal.payment.link_payment.note}</p>
                        </div>
                    )}
                </div>
                {dataForModal.payment.invoice && (
                    <>
                        <div className="withdraw-transaction-details-area">
                            <h2 className="items-header">Items Details</h2>

                            {itemJSX}
                        </div>
                        <div className="withdraw-transaction-details-area">
                            <div className="withdraw-transaction-desc">
                                <h3>Descriptions</h3>
                                <p>{dataForModal.payment.invoice.description}</p>
                            </div>
                            <div className="withdraw-transaction-desc">
                                <h3>Note</h3>
                                <p>{dataForModal.payment.invoice.note}</p>
                            </div>
                        </div>
                        <br></br>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ReceiveTransactionModal;
