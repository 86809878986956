import { Shimmer } from 'react-shimmer';
import './MultipleShimmer.css';
const MultipleShimmer = () => {
    return (
        <div className="notification-box ">
            <div>
                <Shimmer className={'shimmer left-shimmer'} width={'24px'} height={'24px'} />
            </div>
            <div style={{ width: '100%' }}>
                <Shimmer className={'shimmer line-shimmer'} width={'65%'} height={'32px'} />

                <Shimmer className={'shimmer line-shimmer'} width={'55%'} height={'20px'} />
            </div>
        </div>

        // <div className="notification-single-shimmer">
        //      <Shimmer className={'shimmer left-shimmer'} width={'24px'} height={'24px'} />
        //      <Shimmer className={'shimmer line-shimmer'} width={'65%'} height={'32px'} />
        //      <Shimmer className={'shimmer line-shimmer'} width={'55%'} height={'20px'} />

        //      <br></br>
        // </div>
    );
};
export default MultipleShimmer;
