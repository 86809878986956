import './PaymentCard.css';
import RevoCard from './RevoCard';

const PaymentCard = () => {
    return (
        <div className="payment-card">
            <RevoCard />
            {/* <div className="payment-card-img-div"><img src={cardSvg} alt="" /></div> */}
            <p>Upcoming</p>
        </div>
    );
};

export default PaymentCard;
