import { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import './Items.css';
const Items = (props) => {
    const [itemData, setItemData] = useState({ indexKey: props.index });
    const [showDeleteButton, setShowDeleteButton] = useState(true);

    useEffect(() => {
        if (
            itemData.hasOwnProperty('name') &&
            itemData.hasOwnProperty('quantity') &&
            itemData.hasOwnProperty('price') &&
            itemData.name &&
            itemData.quantity > 0 &&
            itemData.price > 0
        ) {
            setShowDeleteButton(false);
            props.changeOnItemData(itemData, props.index);
        } else setShowDeleteButton(true);
    }, [itemData]);

    const removeItemChild = () => {
        props.removeItemChild(props.index);
    };

    return (
        <>
            <div className="sidebar-form-items">
                <div className="sidebar-form-field item-name">
                    <input
                        required
                        type="text"
                        placeholder="Item Name"
                        onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
                    />
                </div>
                <div className="sidebar-form-field item-qty">
                    <input
                        required
                        type="number"
                        min="1"
                        onChange={(e) =>
                            setItemData({ ...itemData, quantity: parseInt(e.target.value) })
                        }
                    />
                </div>
                <div className="sidebar-form-field item-price">
                    <input
                        required
                        type="number"
                        min="1"
                        onChange={(e) =>
                            setItemData({ ...itemData, price: parseInt(e.target.value) })
                        }
                    />
                </div>
                <div className="sidebar-form-field item-total">
                    {itemData && itemData.quantity && itemData.price && (
                        <>${itemData.quantity * itemData.price}</>
                    )}
                </div>
                {props.index !== 0 && (
                    <div className="sidebar-form-field  item-delete-icon" onClick={removeItemChild}>
                        <AiOutlineDelete />
                    </div>
                )}
            </div>
        </>
    );
};

export default Items;
