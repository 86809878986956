import './SettingsPortfolioAdded.css';

const SettingsPortfolioAdded = ({ label, icon, link, btnIcon }) => {
    return (
        <>
            <div className="portfolio-left">
                <span>
                    <img src={icon} alt="" />
                </span>
                <span>{label}</span>
            </div>
            <div className="portfolio-right">
                <a target="_blank" href={link} rel="noreferrer">
                    <button>
                        <span>
                            <img src={btnIcon} alt="" />
                        </span>
                        <span>{label}</span>
                    </button>
                </a>
            </div>
        </>
    );
};

export default SettingsPortfolioAdded;
