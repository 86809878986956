import React from 'react';
import { Shimmer } from 'react-shimmer';
import './MultipleShimmer.css';
const MultipleShimmer = () => {
    return (
        <div className="portfolio-shimmer">
            <div className="left-contents">
                <Shimmer className="circle" width={'50px'} height={'50px'} />
                <Shimmer width={'100px'} height={'30px'} />
            </div>
            <Shimmer className="float-right" width={'123px'} height={'50px'} />
        </div>
    );
};
export default MultipleShimmer;
