import bankIcon from '../../../images/bank.svg';
import verifyIcon from '../../../images/verify.svg';
import './BankAccountInfo.css';

function BankAccountInfo({ data, className, onClick, bankName, accountNumber, bankId }) {
    return (
        <div className={className} onClick={onClick}>
            <div className={`bank-info ${data?.id === bankId ? 'selected' : ''}`}>
                <div className="bank-icon-area">
                    <div className="bank-icon-img">
                        <img src={bankIcon} alt="" />
                    </div>
                    <span className="verify-bank-icon">
                        <img src={verifyIcon} alt="" />
                    </span>
                </div>
                <h3>{bankName}</h3>
                <h4>{accountNumber}</h4>
            </div>
        </div>
    );
}

export default BankAccountInfo;
