import { useRef } from 'react';
import { GrClose } from 'react-icons/gr';
import { toast } from 'react-toastify';
import copyIcon from '../../../../images/copy.svg';
import logo from '../../../../images/logo.svg';
import Modal from '../../../Shared/Modal/Modal';
import './InvoiceLink.css';
const InvoiceLink = ({ data, onClose }) => {
    const childRef = useRef();

    return (
        <Modal ref={childRef} className={'modal-sidebar'} onClose={() => onClose()}>
            <div className="modal-sidebar-content">
                <div className="sidebar-logo">
                    <div className="close-icon">
                        <GrClose onClick={() => (onClose ? childRef.current.closeModal() : '')} />
                    </div>
                    <img src={logo} alt="" />
                </div>
                <div className="invoice-link-area">
                    <h3>Payment Amount ${data.amount}</h3>
                    <a
                        className="invoice-link"
                        target="_blank"
                        href={`${process.env.REACT_APP_REVO_PAYMENT_URL}${data.invoice_id}`}
                        rel="noreferrer"
                    >
                        {process.env.REACT_APP_REVO_PAYMENT_URL}
                        {data.invoice_id}
                    </a>
                    <div>
                        {/* <button className="send-invoice-link">
                            <img src={emailIcon} alt="" />
                            <span>Share via email</span>
                        </button> */}
                        <button
                            onClick={() => {
                                toast(
                                    process.env.REACT_APP_REVO_PAYMENT_URL +
                                        data.invoice_id +
                                        ' copied to clipboard',
                                    {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 2000
                                    }
                                );
                                navigator.clipboard.writeText(
                                    process.env.REACT_APP_REVO_PAYMENT_URL + data.invoice_id
                                );
                            }}
                            className="send-invoice-link"
                        >
                            <img src={copyIcon} alt="" />
                            <span>Copy Link</span>
                        </button>
                    </div>
                    <div className="send-to-area">
                        <h5>Please copy the payment link and share it with your client</h5>
                        {/* <div className="send-to-icons">
                            <SendTo img={whatsapplIcon} name={'WhatsApp'} />
                            <SendTo img={telegramIcon} name={'Telegram'} />
                            <SendTo img={messengerIcon} name={'Messenger'} />
                            <SendTo img={InstagramIcon} name={'Instagram'} />
                        </div> */}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default InvoiceLink;
