import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { HiOutlineArrowSmDown } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import InvoicePayIcon from '../../../../images/InvoicePayTnx.svg';
import LinkPayIcon from '../../../../images/LinkPayTnx.svg';
import NoDataFound from '../../../Shared/NoDataFound/NoDataFound';
import ReceiveTransactionModal from '../TransactionModal/ReceiveTransactionModal';
import WithdrawTransactionModal from '../TransactionModal/WithdrawTransactionModal';
import MultipleShimmer from './MultipleShimmer';
import './Transactions.css';
const Transactions = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isSwap, setIsSwap] = useState();
    useEffect(() => setIsSwap(true), []);

    const [fields, setFields] = useState(false);

    const [dataForModal, setDataForModal] = useState();

    useEffect(() => {
        let data;
        isSwap
            ? props.receivedTransactionsData
                ? (data = props.receivedTransactionsData)
                : (data = [])
            : props.withdrawTransactionsData
            ? (data = props.withdrawTransactionsData)
            : (data = []);
        if (data) {
            setFields(
                data.map((data, i) => (
                    <div
                        onClick={() => {
                            setShowModal(true);
                            setDataForModal(data);
                        }}
                        key={data.id}
                        className={
                            i % 2 === 0
                                ? 'odd-column transaction-data-area'
                                : 'tr-color transaction-data-area'
                        }
                    >
                        <div className="transaction-data">
                            <div className="download-icon">
                                <span>
                                    {isSwap ? (
                                        data.payment.invoice ? (
                                            <img src={InvoicePayIcon} />
                                        ) : (
                                            <img src={LinkPayIcon} />
                                        )
                                    ) : (
                                        <BiDownload />
                                    )}
                                </span>
                            </div>
                            <div className="transaction-data-title">
                                <h4>
                                    {isSwap
                                        ? data.payment.invoice
                                            ? data.payment.invoice.client_email
                                            : data.payment.link_payment.payer_email
                                        : data.withdraw.bank_name +
                                          '....' +
                                          String(data.withdraw.account_number).slice(-4)}
                                </h4>
                                {/* <h4>{data.transaction_id}</h4> */}
                                <p>{moment(new Date(data.createdAt), 'MMDDYYYY').fromNow()}</p>
                            </div>
                        </div>
                        <div className="transaction-amount">
                            <span>
                                $
                                {isSwap
                                    ? data.amount_in.toFixed(2)
                                    : data.withdraw.withdraw_amount.toFixed(2)}
                            </span>
                            <p
                                className={`transaction-status transaction-status-${data.status.toLocaleLowerCase()}`}
                            >
                                {data.status}
                            </p>
                        </div>
                    </div>
                ))
            );
        }
    }, [isSwap, props]);

    return (
        <>
            {!isSwap && showModal && dataForModal && (
                <WithdrawTransactionModal
                    dataForModal={dataForModal}
                    onClose={() => setShowModal(false)}
                />
            )}
            {isSwap && showModal && dataForModal && (
                <ReceiveTransactionModal
                    dataForModal={dataForModal}
                    onClose={() => setShowModal(false)}
                />
            )}

            <div className="transaction-main">
                <div className="transaction-top">
                    <div className="transaction-title-area">
                        <div className="transaction-title">
                            <h3>Transactions</h3>
                        </div>
                        <div>
                            <Link to="/transactions" className="see-all">
                                See All
                            </Link>
                        </div>
                    </div>
                    <div className="select-swap-btn-area">
                        <div className="transaction-select">
                            {/* <select name="" id="">
                                <option value="">Latest</option>
                                <option value="">Old</option>
                            </select> */}
                        </div>
                        <div className="swap-btn-area">
                            <button
                                onClick={() => setIsSwap(true)}
                                className={`swap-btn ${isSwap ? 'transaction-btn' : ''}`}
                            >
                                <span>
                                    <BiDownload />
                                </span>
                                <span>Received</span>
                            </button>
                            <button
                                onClick={() => setIsSwap(false)}
                                className={`swap-btn ${isSwap ? '' : 'transaction-btn'}`}
                            >
                                <span>
                                    <HiOutlineArrowSmDown />
                                </span>
                                <span>Withdraw</span>
                            </button>
                        </div>
                    </div>
                    <div className="transaction-wrapper">
                        {props.isLoading ? (
                            <>
                                <MultipleShimmer />
                                <MultipleShimmer />
                                <MultipleShimmer />
                                <MultipleShimmer />
                                <MultipleShimmer />
                            </>
                        ) : fields ? (
                            fields
                        ) : (
                            ''
                        )}
                        {!props.isLoading && fields.length === 0 && <NoDataFound />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Transactions;
