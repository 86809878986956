import React from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import './ChangePassword.css';

const EyeButton = ({ show, ...rest }) => {
    return (
        <div {...rest} className="password-eye-bar">
            {show ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </div>
    );
};

export default EyeButton;
