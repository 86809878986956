import { useEffect, useState } from 'react';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auths';
import EarningCard from '../EarningCard/EarningCard';
import Invoice from '../Invoice/Invoice';
import NoteCard from '../NoteCard/NoteCard';
import PaymentCard from '../PaymentCard/PaymentCard';
import Transactions from '../Transactions/Transactions';
import './Home.css';
const Home = () => {
    const [userData, setUserData] = useState();
    const [earningData, setEarningData] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [receivedTransactionsData, setReceivedTransactionsData] = useState();
    const [withdrawTransactionsData, setWithdrawTransactionsData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            let data = await apiCall('/earnings', 'GET');
            setEarningData(data.data);
            data = await apiCall('/invoices?limit=10&offset=0', 'GET');
            setInvoiceData(data.data);
            data = await apiCall('/withdraw/received_transactions?limit=10&offset=0', 'GET');
            setReceivedTransactionsData(data.data);
            data = await apiCall('/withdraw/withdraw_transactions?limit=10&offset=0', 'GET');
            setWithdrawTransactionsData(data.data);
            data = await apiCall('/user', 'GET');
            setUserData(data.data[0]);
            setIsLoading(false);
        };
        fetchData();
    }, []);
    useEffect(() => {
        document.title = 'Revorium - Home';
    });

    return (
        <>
            <div className="card-area">
                <EarningCard userData={userData} data={earningData} isLoading={isLoading} />
                <NoteCard isLoading={isLoading} paylink_key={userData && userData.paylink_key} />
                <PaymentCard />
            </div>
            <div className="invoice-transactions">
                <Invoice data={invoiceData} isLoading={isLoading} />
                <Transactions
                    isLoading={isLoading}
                    withdrawTransactionsData={withdrawTransactionsData}
                    receivedTransactionsData={receivedTransactionsData}
                />
            </div>
        </>
    );
};

export default Home;
