import React from 'react';

function Checkbox({ label, ...rest }) {
    return (
        <div className="form-checkbox">
            <input {...rest} type="checkbox" />
            <label>{label}</label>
        </div>
    );
}

export default Checkbox;
