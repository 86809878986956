import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Shimmer } from 'react-shimmer';
import apiCall from '../../../../api/apiCall';
import { getAccessToken } from '../../../Login/Auths';
import Form from '../../../Shared/Form/Form';
import TextInput from '../../../Shared/Form/TextInput/TextInput';
import SettingsTitle from '../../../Shared/SettingsTitle/SettingsTitle';
import './Profile.css';

function Profile() {
    // const [isShow, setIsShow] = useState(false);
    // const [isImageLoading, setImageLoading] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [profileData, setProfileData] = useState({
        fullName: '',
        email: '',
        phone: ''
    });

    const [msg, setMsg] = useState();
    const token = getAccessToken();

    useEffect(() => {
        document.title = 'Revorium -  Profile';
    }, []);

    useEffect(() => {
        const getUserProfile = async () => {
            setIsLoading(true);

            const response = await apiCall(`/user`);
            setMsg(response.ok);
            if (response.ok) {
                setProfileData(response.data[0]);
            } else {
            }

            setIsLoading(false);
        };
        getUserProfile();
    }, []);

    return (
        <>
            <div>
                <SettingsTitle title={'Your Details'} />
                <div className="view-profile-area">
                    <div className="view-profile-top">
                        <div className="view-profile-left">
                            <Avatar
                                size={50}
                                name={profileData ? profileData.fullName : ''}
                                className="image-on-profile"
                            />
                            <div className="settings-profile-name">
                                {!isLoading && <h2>{profileData.fullName}</h2>}
                                {isLoading && <Shimmer width={'300px'} height={'32px'} />}
                            </div>
                        </div>
                    </div>
                    <div className="view-profile">
                        <Form className={'settings-form-area'}>
                            {isLoading && (
                                <>
                                    <Shimmer width={'90%'} height={'50px'} />
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <Shimmer width={'90%'} height={'50px'} />
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <Shimmer width={'90%'} height={'50px'} />
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </>
                            )}
                            {!isLoading && (
                                <>
                                    <TextInput
                                        type="text"
                                        label="Name"
                                        value={profileData.fullName}
                                        disabled
                                    />
                                    <br></br>
                                    <TextInput
                                        type="text"
                                        label="Phone Number"
                                        value={profileData.phone}
                                        disabled
                                    />
                                    <br></br>
                                    <TextInput
                                        type="text"
                                        label="Email Address"
                                        value={profileData.email}
                                        disabled
                                    />
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;
