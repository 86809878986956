/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { onMessageListener, requestForToken } from './firebase';
import icon from './images/logo.png';
const FirebaseNotification = ({ increaseNotificationCount }) => {
    const [isTokenFound, setTokenFound] = useState(false);
    requestForToken();
    // const messaging = getMessaging(firebaseApp);

    // const [notification, setNotification] = useState({ title: '', body: '' });

    onMessageListener()
        .then((payload) => {
            const notificationTitle = payload.data.title;
            const notificationOptions = {
                body: payload.data.body,
                // icon: logo
                icon
            };
            increaseNotificationCount();
            const n = new Notification(notificationTitle, notificationOptions);
        })
        .catch((err) => console.log('failed: ', err));
};

// Notification();
export default FirebaseNotification;
