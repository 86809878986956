/* eslint-disable no-restricted-globals */
import apiCall from 'api/apiCall';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
    apiKey: 'AIzaSyDXKjWWQfA3ejHMDjUPK9vz3RyK3bXpTUs',
    authDomain: 'revorium-production.firebaseapp.com',
    projectId: 'revorium-production',
    storageBucket: 'revorium-production.appspot.com',
    messagingSenderId: '148574781385',
    appId: '1:148574781385:web:5db635fde443ed49273218',
    measurementId: 'G-SFHZ3Y4WQ6'
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
export const requestForToken = () => {
    Notification.requestPermission().then((permission) => {
        if (permission !== 'granted') {
            console.log('permission denied');
        }
    });
    return getToken(messaging, {
        vapidKey:
            'BPQFMh4bDIl-uHoOS1cBKNHBBj551ldOGXzWqwX_Cn02rAiyQYgsVkfgu_2y2AYVLJa83gzPz8udwCNb8YmsAhA'
    })
        .then(async (currentToken) => {
            if (currentToken) {
                const response = await apiCall(`/user`, 'PATCH', {
                    device_token: currentToken,
                    device_id: localStorage.getItem('deviceID')
                });
                if (response.ok) {
                    // console.log('current token for client: ', currentToken);
                } else {
                    // console.log('token save error: ', currentToken);
                }
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            // self.registration.showNotification(notificationTitle, notificationOptions);
            resolve(payload);
        });
    });

// import { getMessaging, onMessage } from 'firebase/messaging';

// const firebaseConfig = {
//     apiKey: 'AIzaSyBdyvmNr4dp8ycLEumScKLHh8A9vU66p24',
//     authDomain: 'revoweb.firebaseapp.com',
//     projectId: 'revoweb',
//     storageBucket: 'revoweb.appspot.com',
//     messagingSenderId: '724196399996',
//     appId: '1:724196399996:web:f13e74c851ab4cb0cc22f9',
//     measurementId: 'G-FF3J39J8YD'
// };
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);

// export const getToken = (setTokenFound) => {
//     return getToken(messaging, {
//         vapidKey:
//             'BKPAHqOtYAd231jX6yQM_Ch6JzEOtBdbXBhgX7jBmFjX8vp0timIO_OQ-_QepIgN2IdukMiznM8YixcIJExKoA8'
//     })
//         .then((currentToken) => {
//             if (currentToken) {
//                 console.log('current token for client: ', currentToken);
//                 setTokenFound(true);
//                 // Track the token -> client mapping, by sending to backend server
//                 // show on the UI that permission is secured
//             } else {
//                 console.log('No registration token available. Request permission to generate one.');
//                 setTokenFound(false);
//                 // shows on the UI that permission is required
//             }
//         })
//         .catch((err) => {
//             console.log('An error occurred while retrieving token. ', err);
//             // catch error while creating client token
//         });
// };
// // Initialize Firebase
// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             resolve(payload);
//         });
//     });
// export default firebaseApp;
