import apiCall from 'api/apiCall';
import { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../../Loading/Loading';
import { setTokens } from '../../Login/Auths';
import LoginRegister from '../LoginRegister';
import Country from './Country/Country';
import CreatePassword from './CreatePassword/CreatePassword';
import Email from './Email/Email';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import Phone from './Phone/Phone';
// import SuccessMessage from '../AlertMessage/SuccessMessage';

const Register = ({ setIsLoggedIn }) => {
    let deviceID = localStorage.getItem('deviceID');
    if (!deviceID) {
        deviceID = uuidv4();
        localStorage.setItem('deviceID', deviceID);
    }
    const navigate = useNavigate();
    const [pageState, setPageState] = useState('email');
    const [msg, setMsg] = useState('Enter your Details');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({
        deviceID,
        name: '',
        password: '',
        confirm_password: '',
        email: '',
        photo_url: '',
        phone: '',
        city: '',
        country_id: 18,
        calling_code: '+880',
        address: '',
        postal_code: '',
        dob: '',
        email_otp: '',
        mobile_otp: '',
        country_code: 'BD'
    });

    useEffect(() => {
        document.title = 'Sign Up';
    });

    // Register function
    const signUp = async () => {
        setIsLoading(true);
        userData.phone = userData.calling_code + userData.phone;
        const response = await apiCall(`/register`, 'POST', userData);

        if (response.ok) {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            setTokens(response.data.tokens);
            setMsg('Signup Successful');
            setIsLoggedIn(true);
            navigate('/');
        } else {
            setMsg(response.msg);
            setIsError(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (pageState === 'submit') signUp();
        if (pageState === 'email') setMsg('Enter your email address');
        if (pageState === 'phone') setMsg('Enter your valid phone number');
        if (pageState === 'country') setMsg('Country of residence');
        if (pageState === 'password')
            setMsg('Password must be 8 characters long with at least one number and one letter');
        if (pageState === 'details') setMsg('Personal details');
    }, [pageState]);

    const backPageState = () => {
        if (pageState === 'phone') setPageState('email');
        if (pageState === 'country') setPageState('phone');
        if (pageState === 'password') setPageState('country');
        if (pageState === 'details') setPageState('password');
    };

    return (
        <>
            {isLoading && <Loading />}
            <LoginRegister>
                <div className="login-register-top sign-up">
                    {pageState !== 'email' && (
                        <div className="register-back-button" onClick={backPageState}>
                            <span>
                                <IoIosArrowBack />
                            </span>
                        </div>
                    )}

                    <h1>Sign Up</h1>

                    {msg && (
                        <>
                            <p>{msg}</p>
                        </>
                    )}
                </div>
                <div className="login-register-form">
                    {pageState === 'email' && (
                        <Email
                            userData={userData}
                            setUserData={setUserData}
                            setPageState={setPageState}
                            /* className={'error-field'} */
                            setIsLoading={setIsLoading}
                        />
                    )}

                    {pageState === 'phone' && (
                        <Phone
                            userData={userData}
                            setUserData={setUserData}
                            setPageState={setPageState}
                            setIsLoading={setIsLoading}
                        />
                    )}

                    {pageState === 'country' && (
                        <Country
                            userData={userData}
                            setUserData={setUserData}
                            setPageState={setPageState}
                            setIsLoading={setIsLoading}
                        />
                    )}

                    {pageState === 'password' && (
                        <CreatePassword
                            userData={userData}
                            setUserData={setUserData}
                            setPageState={setPageState}
                            setIsLoading={setIsLoading}
                        />
                    )}

                    {(pageState === 'details' || pageState === 'submit') && (
                        <PersonalDetails
                            setPageState={setPageState}
                            setUserData={setUserData}
                            userData={userData}
                        />
                    )}

                    <div className="bottom-bar">
                        <p>
                            Already have an account ? <Link to="/login">Sign In </Link>
                        </p>
                    </div>
                </div>
            </LoginRegister>
        </>
    );
};

export default Register;
