import moment from 'moment';
import { useRef, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Loading from '../../Loading/Loading';
import Modal from '../../Shared/Modal/Modal';
import Icon from './notification-bell-icon.svg';
import './NotificationModal.css';

const NotificationModal = ({ data, onClose }) => {
    const childRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Modal ref={childRef} className={'modal-sidebar'} onClose={() => onClose()}>
                {true && (
                    <div className="modal-sidebar-content">
                        {isLoading && <Loading />}
                        <div className="withdraw-top">
                            <div className="close-icon">
                                <GrClose onClick={() => childRef.current.closeModal()} />
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <img src={Icon} alt="Informations" />
                            <br></br>
                            <br></br>
                            <h3>{data.title}</h3>

                            <p>
                                {moment(new Date(data.updatedAt)).format('Do MMM YYYY, h:mm:ss a')}
                            </p>

                            <hr></hr>

                            <div className="total-info-container">
                                <p>{data.body}</p>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default NotificationModal;
