import apiCall from 'api/apiCall';
import NoDataFound from 'components/Shared/NoDataFound/NoDataFound';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DatePicker } from 'react-rainbow-components';
import { Shimmer } from 'react-shimmer';
import './Statement.css';
function Statement() {
    const [from, setFrom] = useState(moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [statementData, setStatementData] = useState([]);
    useEffect(() => {
        document.title = 'Revorium - Statement';
    });
    const [msg, setMsg] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const getStatements = async () => {
        setIsLoading(true);

        const response = await apiCall(`/statement/${from}/${to}`);

        if (response.ok) {
            setStatementData(response.data);
        } else {
            setMsg(response.msg);
        }

        setIsLoading(false);
    };

    const [fields, setFields] = useState(false);

    useEffect(() => {
        if (statementData.length > 0) {
            let i = 0;
            setFields(
                statementData.map((data, i) => (
                    <tr
                        className={
                            i % 2 === 0 ? 'odd-column single-transaction' : 'single-transaction'
                        }
                        key={data.id}
                    >
                        <td>
                            <div className="transaction-data-title">
                                <h4>{data.transaction_id}</h4>
                            </div>
                        </td>
                        <td>
                            {data.transaction_type === 'Receive'
                                ? data.payment.invoice
                                    ? 'Payment For Invoice : ' + data.payment.invoice.invoice_id
                                    : 'Payment For Paylink : ' +
                                      data.payment.link_payment.payment_id
                                : 'Withdraw Money on Bank : ' + data.withdraw.bank_name}
                        </td>
                        <td>
                            {data.transaction_type === 'Withdraw'
                                ? data.withdraw.withdraw_amount.toFixed(2)
                                : 0}
                        </td>
                        <td>{data.amount_in.toFixed(2)}</td>

                        <td>{data.balance}</td>
                        <td>{data.status}</td>
                        <td>
                            {moment(new Date(data.updatedAt), 'MMDDYYYY').format(
                                'Do MMM YYYY, h:mm:ss a'
                            )}
                        </td>
                    </tr>
                ))
            );
        }
    }, [statementData]);

    useEffect(() => {
        if (from && to) {
            setMsg();
            if (from > to) {
                setMsg('Invalid Date Range');
            } else {
                getStatements();
            }
        }
    }, [from, to]);

    return (
        <>
            <div className="full-transaction">
                <div className="transaction-top">
                    <div className="transaction-title-area">
                        <div className="transaction-selct-area">
                            <div className="transaction-title">
                                <h3>Statement</h3>
                            </div>
                            <DatePicker
                                className="statement-datepicker"
                                value={from}
                                onChange={(d) => setFrom(moment(d).format('YYYY-MM-DD'))}
                                formatStyle="medium"
                                placeholder="From"
                                // minDate={new Date(1900, 0, 0)}
                                // maxDate={new Date(2012, 0, 0)}
                            />
                            <DatePicker
                                className="statement-datepicker"
                                value={to}
                                onChange={(d) => setTo(moment(d).format('YYYY-MM-DD'))}
                                formatStyle="medium"
                                placeholder="To"
                                // minDate={new Date(1900, 0, 0)}
                                // maxDate={new Date(2012, 0, 0)}
                            />
                        </div>
                    </div>
                    {msg && <p>{msg}</p>}

                    <div className="statement-wrapper">
                        <div className="transaction-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Transaction ID</th>
                                        <th>Description</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>

                                {!isLoading && (
                                    <>
                                        <tbody>{fields && fields}</tbody>
                                    </>
                                )}
                            </table>

                            {isLoading && (
                                <>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                    <br></br>
                                    <Shimmer width={'100%'} height={'50px'} />
                                </>
                            )}
                        </div>
                        {!isLoading && fields && fields.length === 0 && <NoDataFound />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Statement;
