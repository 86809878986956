import apiCall from 'api/apiCall';
import SubmitButton from 'components/LoginRegister/FormFields/SubmitButton/SubmitButton';
import TextInput from 'components/LoginRegister/FormFields/TextInput/TextInput';
import { useEffect, useState } from 'react';
import ErrorMessage from '../../AlertMessage/ErrorMessage';
import country from '../../country.json';
import VerifyOtp from './VerifyOtp';

const Phone = ({ setPageState, setUserData, userData, setIsLoading }) => {
    const [msg, setMsg] = useState();
    const [gotoVerify, setGotoVerify] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    useEffect(() => {
        setMsg('');
        if (userData.calling_code !== '+880') setIsPhoneValid(true);
        else {
            setIsPhoneValid(false);
            if (userData.phone[0] === '1' && userData.phone.length > 9) setIsPhoneValid(true);
            else setMsg('Phone Number must be 10 digit');
            if (userData.phone.length > 9) {
                setUserData({ ...userData, phone: userData.phone.substring(0, 10) });
            }
        }
    }, [userData.phone, userData.calling_code]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const res = await apiCall('/send_otp_to_mobile', 'POST', {
            phone: userData.calling_code + userData.phone
        });
        setIsLoading(false);
        if (res.ok) {
            setGotoVerify(1);
        } else {
            setMsg(res.msg);
        }
    };

    const verified = (otp) => {
        setUserData({ ...userData, mobile_otp: otp });
        setPageState('country');
    };

    return (
        <>
            {!gotoVerify && (
                <form onSubmit={handleSubmit}>
                    <div className="phone-field-area">
                        <select
                            className="phone-select-field"
                            value={userData.calling_code}
                            onChange={(e) =>
                                setUserData({ ...userData, calling_code: e.target.value })
                            }
                        >
                            {country.map((data) => (
                                <>
                                    <option key={data.iso} value={'+' + data.code}>
                                        {data.iso} ({'+' + data.code})
                                    </option>
                                </>
                            ))}
                        </select>
                        <TextInput
                            value={userData.phone}
                            type="number"
                            onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                            required
                            className={'phone-field'}
                        />
                    </div>
                    <SubmitButton type="submit" disabled={!isPhoneValid}>
                        Next
                    </SubmitButton>

                    {msg && <ErrorMessage>{msg}</ErrorMessage>}
                </form>
            )}

            {gotoVerify && (
                <VerifyOtp
                    resendOtp={handleSubmit}
                    success={verified}
                    setIsLoading={setIsLoading}
                    phone={userData.calling_code + userData.phone}
                />
            )}
        </>
    );
};

export default Phone;
