import { forwardRef, useImperativeHandle, useState } from 'react';
import './Modal.css';

const Modal = forwardRef((props, ref) => {
    const [closingModal, setClosingModal] = useState(false);
    const closeModal = () => {
        setClosingModal(true);
        setTimeout(() => {
            props.onClose();
        }, 190);
    };

    useImperativeHandle(ref, () => ({
        closeModal
    }));

    return (
        <div
            className={`modal ${props.className}`}
            onClick={() => (props.onClose ? closeModal() : '')}
        >
            <div
                className={'modal_content ' + (closingModal ? 'close_modal' : '')}
                onClick={(e) => e.stopPropagation()} /* stop event bubbling */
            >
                {props.children}
            </div>
        </div>
    );
});
export default Modal;
