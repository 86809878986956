// import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { DatePicker } from 'react-rainbow-components';
import { toast } from 'react-toastify';
import apiCall from '../../../../api/apiCall';
import logo from '../../../../images/logo.svg';
import Loading from '../../../Loading/Loading';
import { getAccessToken } from '../../../Login/Auths';
import Modal from '../../../Shared/Modal/Modal';

import InvoiceLink from '../InvoiceLink/InvoiceLink';
import Items from './Items';
import './RequestMoney.css';
const RequestMoney = (props) => {
    const childRef = useRef();
    const [name, setName] = useState();
    const [invoiceData, setInvoiceData] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [resData, setResData] = useState();

    const [msg, setMsg] = useState();
    const token = getAccessToken();
    const [itemData, setItemData] = useState([]);

    const changeOnItemData = (data, index) => {
        const items = [...itemData];
        items[index] = data;
        setItemData(() => items);
    };

    const removeItemChild = (index) => {
        if (itemJSX.length < 1) {
            setMsg('cannot delete item');
            return;
        }
        setItemData((itemData) => {
            return itemData.filter((value, i) => value && value.indexKey != index);
        });

        setItemJSX((itemJSX) => {
            return itemJSX.filter((value, i) => value.key != index);
        });
        setCount(itemData.length);
    };
    const [itemJSX, setItemJSX] = useState([
        <Items
            key={count}
            index={count}
            changeOnItemData={changeOnItemData}
            removeItemChild={removeItemChild}
        />
    ]);
    useEffect(() => {
        let sum = 0;
        for (let i = 0; i < itemData.length; i++) {
            if (itemData[i]) sum += itemData[i].quantity * itemData[i].price;
        }
        setTotalAmount(sum);
        setInvoiceData({ ...invoiceData, amount: sum });
    }, [itemData]);

    let user;
    if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user'));
    }
    useEffect(() => {
        if (user) {
            setName(user.fullName);
        }
        setCount(() => count + 1);
    }, []);

    const addItemField = () => {
        if (itemData.length < count) {
            setMsg('cannot add item until previous is filled');
            return;
        }
        setCount(() => count + 1);
        setItemJSX(
            itemJSX.concat(
                <Items
                    key={count}
                    index={count}
                    changeOnItemData={changeOnItemData}
                    removeItemChild={removeItemChild}
                />
            )
        );
    };

    const createLink = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        let finalItemDataArray = [];
        for (let i = 0; i < itemData.length; i++) {
            if (itemData[i]) {
                if (
                    !itemData[i].hasOwnProperty('name') ||
                    !itemData[i].hasOwnProperty('quantity') ||
                    !itemData[i].hasOwnProperty('price')
                ) {
                    setMsg('invalid itemdata ' + i);
                } else {
                    finalItemDataArray.push(itemData[i]);
                }
            } else {
                setMsg('invalid item ' + i);
            }
        }

        const responseData = await apiCall(`/invoice`, 'POST', {
            invoiceData,
            itemData: finalItemDataArray
        });
        setIsLoading(false);
        if (responseData.ok) {
            setMsg('Invoice Created Successfully');
            props.addInvoiceData(responseData.data.invoice);
            setResData(responseData.data.invoice);
            setIsLoading(false);
            setShowModal(true);

            toast(
                'Invoice Created Successfully. Invoice ID: ' + responseData.data.invoice.invoice_id,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000
                }
            );
        } else {
            setMsg(responseData.msg);
            toast('Invoice Created Failed: ' + responseData.msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000
            });
        }
    };

    return (
        <>
            {showModal && resData && (
                <InvoiceLink
                    data={resData}
                    onClose={() => {
                        props.onClose();
                        setShowModal(false);
                    }}
                />
            )}
            {!showModal && (
                <Modal ref={childRef} className={'modal-sidebar'} onClose={() => props.onClose()}>
                    <div className="modal-sidebar-content">
                        {isLoading && <Loading />}
                        <div className="sidebar-logo">
                            <div className="close-icon">
                                <GrClose onClick={() => childRef.current.closeModal()} />
                            </div>
                            <img src={logo} alt="" />
                            <h3>{name}</h3>
                        </div>
                        <form className="sidebar-form" onSubmit={createLink}>
                            <div className="sidebar-form-field">
                                <label htmlFor="Client Email">Client Name</label>
                                <input
                                    required
                                    type="text"
                                    placeholder="Enter Your Client Name"
                                    onChange={(e) =>
                                        setInvoiceData({
                                            ...invoiceData,
                                            client_name: e.target.value
                                        })
                                    }
                                />
                            </div>
                            <div className="sidebar-form-field">
                                <label htmlFor="Client Email">Client Email</label>
                                <input
                                    required
                                    type="text"
                                    placeholder="Enter Your Client Email"
                                    onChange={(e) =>
                                        setInvoiceData({
                                            ...invoiceData,
                                            client_email: e.target.value
                                        })
                                    }
                                />
                            </div>
                            <div className="sidebar-form-field">
                                <label htmlFor="Description">Project / Description</label>
                                <input
                                    required
                                    type="text"
                                    placeholder="Write a short description"
                                    onChange={(e) =>
                                        setInvoiceData({
                                            ...invoiceData,
                                            description: e.target.value
                                        })
                                    }
                                />
                            </div>
                            <div className="sidebar-form-items">
                                <div className="sidebar-form-field item-name">
                                    <label htmlFor="Item">Item</label>
                                </div>
                                <div className="sidebar-form-field item-qty">
                                    <label htmlFor="Qty">Qty</label>
                                </div>
                                <div className="sidebar-form-field item-price">
                                    <label htmlFor="Price">Price</label>
                                </div>
                                <div className="sidebar-form-field item-total">
                                    <label htmlFor="Total">Total</label>
                                </div>
                            </div>
                            <>{itemJSX}</>

                            <div className="additem-total">
                                <div className="additem" onClick={addItemField}>
                                    <span>
                                        <AiOutlinePlus />
                                    </span>
                                    <span>Add Item</span>
                                </div>
                                <div className="item-total">
                                    <span>Total</span>
                                    <strong>${invoiceData.amount ? invoiceData.amount : 0}</strong>
                                </div>
                            </div>
                            <div className="sidebar-form-field">
                                <label htmlFor="Date">Due Date</label>
                                {/* <input
                                    required
                                    type="date"
                                    onChange={(e) =>
                                        setInvoiceData({ ...invoiceData, due_date: e.target.value })
                                    }
                                /> */}
                                <DatePicker
                                    className="datePicker"
                                    value={invoiceData.due_date}
                                    onChange={(d) =>
                                        setInvoiceData({ ...invoiceData, due_date: d })
                                    }
                                    formatStyle="mediumm"
                                    minDate={new Date()}
                                    maxDate={
                                        new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
                                    }
                                />
                            </div>

                            <div className="sidebar-form-field">
                                <label htmlFor="Descripton">Additional Notes</label>
                                <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="2"
                                    onChange={(e) =>
                                        setInvoiceData({ ...invoiceData, note: e.target.value })
                                    }
                                ></textarea>
                            </div>
                            <div className="create-link">
                                <button type="submit" className="btn-create-link">
                                    Create Link
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default RequestMoney;
