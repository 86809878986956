import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { COUNTRIES } from './Countries';
import './CountrySelector.css';

const CountrySelector = React.forwardRef((props, ref) => {
    useEffect(() => {
        const mutableRef = ref;

        const handleClickOutside = (event) => {
            if (mutableRef.current && !mutableRef.current.contains(event.target) && props.open) {
                props.onToggle();
                setQuery('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const [query, setQuery] = useState('');

    return (
        <div ref={ref}>
            <div>
                <button
                    className="country-select-button"
                    type="button"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                    onClick={props.onToggle}
                >
                    <span className="truncate">
                        <img
                            alt={`${props.selectedValue.value}`}
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${props.selectedValue.value}.svg`}
                            className={'inline mr-2 h-4 rounded-sm'}
                        />
                        {props.selectedValue.title}
                    </span>
                    <span className="right-arrow-icon">
                        <svg
                            className="h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </button>

                <AnimatePresence>
                    {props.open && (
                        <motion.ul
                            className={'select_country_area'}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.1 }}
                            tabIndex={-1}
                            role="listbox"
                            aria-labelledby="listbox-label"
                            aria-activedescendant="listbox-option-3"
                        >
                            <div>
                                <li className="country-search-area">
                                    <input
                                        className="country-search-field"
                                        type="search"
                                        name="search"
                                        autoComplete={'off'}
                                        placeholder={'Search a country'}
                                        onChange={(e) => setQuery(e.target.value)}
                                    />
                                </li>
                            </div>

                            <div className="select-country-list">
                                {COUNTRIES.filter((country) =>
                                    country.title.toLowerCase().startsWith(query.toLowerCase())
                                ).length === 0 ? (
                                    <li>No countries found</li>
                                ) : (
                                    COUNTRIES.filter((country) =>
                                        country.title.toLowerCase().startsWith(query.toLowerCase())
                                    ).map((value, index) => {
                                        return (
                                            <li
                                                key={`${props.id}-${index}`}
                                                id="listbox-option-0"
                                                role="option"
                                                onClick={() => {
                                                    props.onChange(value.value);
                                                    setQuery('');
                                                    props.onToggle();
                                                }}
                                            >
                                                <img
                                                    alt={`${value.value}`}
                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.value}.svg`}
                                                />

                                                <span>{value.title}</span>
                                                {value.value === props.selectedValue.value ? (
                                                    <span className="selected-icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                ) : null}
                                            </li>
                                        );
                                    })
                                )}
                            </div>
                        </motion.ul>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
});

export default CountrySelector;
